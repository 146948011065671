<template>
  <el-row justify="end" class="feedback-dialog">
    <el-affix position="bottom" v-show="!feedbackDialogVisible">
      <el-button class="feedback-dialog-btn" round @click="toggleDialog()">
        <img :src="FeedbackDialogIcon" />
        <el-text size="large">{{ t("open_dialog") }}</el-text>
      </el-button>
    </el-affix>
    <el-affix v-show="feedbackDialogVisible" position="bottom">
      <el-card v-loading="savingFeedback">
        <template #header>
          <el-row justify="space-between">
            <el-row justify="space-between">
              <img :src="FeedbackDialogIcon" />
              <el-text size="large">{{ t("header") }}</el-text>
            </el-row>
            <el-button class="feedback-dialog-btn-close" plain text size="large" @click="toggleDialog()">
              <el-icon>
                <Close />
              </el-icon>
            </el-button>
          </el-row>
        </template>
        <template #default>
          <el-row>
            <el-text type="info" size="small">{{ t("text1") }}</el-text>
          </el-row>
          <el-row>
            <el-input type="textarea" v-model="feedbackText" :rows="4"></el-input>
          </el-row>
          <el-row>
            <el-col>
              <el-button type="primary" @click="sendFeedback">{{ t("send") }}</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-text type="info" size="small">{{ t("text2") }}</el-text>
          </el-row>
        </template>
      </el-card>
    </el-affix>
  </el-row>
</template>
<script lang="ts" setup>
import { useToggle } from "@vueuse/core"
import { Close } from "@element-plus/icons-vue"
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useTranslation } from "i18next-vue"
import { saveFeedback } from "@/services/rest/feedback"
import { delay } from "@/tools"
import FeedbackDialogIcon from "@/assets/feedback_dialog.svg"

const { i18next } = useTranslation()
const t = i18next.getFixedT(null, "feedback_dialog")
const [feedbackDialogVisible, toggleDialog] = useToggle(false)
const [savingFeedback, toggleSaving] = useToggle(false)

const feedbackText = ref("")

const r = useRouter()

const sendFeedback = async () => {
  toggleSaving(true)
  await saveFeedback({ path: r.currentRoute.value.fullPath }, feedbackText.value)
  feedbackText.value = ""
  await delay(1000)
  toggleSaving(false)
  toggleDialog(false)
}
</script>
<style scoped>
.feedback-dialog {
  margin-right: 16px;
}

.feedback-dialog img {
  margin-right: 8px;
}

.feedback-dialog-btn {
  box-shadow: var(--el-box-shadow-light);
  border: none;
}

.feedback-dialog-btn-close {
  background: none !important;
  padding: 0;
  height: 16px;
  font-size: 16px;
}

.el-card {
  width: 332px;
  border-radius: 20px;
  border: none;
}

:deep(.el-card__body) {
  padding: 0 20px 5px;
}

:deep(.el-card__body) .el-row {
  margin: 0 0 15px;
}

:deep(.el-card__header) {
  border: none;
}

:deep(.el-text--large) {
  --el-text-font-size: var(--el-font-size-large);
  font-weight: 500;
}
</style>
