import settings from "./config"

import { createApp } from "vue"
import { createPinia } from "pinia"
import ElementPlus from "element-plus"
import "./styles/main.scss"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

import "dayjs/locale/de.js"
dayjs.locale("de")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as _ from "element-plus/global"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as __ from "i18next-vue/dist/index"

import { sync as authStorageSync } from "@/store/auth/storage"
// import { init as initNotifications } from "@/services/webpush/index";

import App from "./App.vue"
import router from "./router"

import i18next from "i18next"
import I18NextVue from "i18next-vue"
import { init as i18init } from "@/services/lang"

import useAuthStore from "@/store/auth"

import { client as apiClient, init as initApi, tokenHeader } from "@/services/rest"
import { isTokenEmptyOrExpired } from "@/tools"

import { init as routerStoreInit } from "@/store/route"
import { apply as applyAclGuard } from "@/router/aclguard"

initApi(settings.api, () => useAuthStore().token ?? "")

apiClient.interceptors.response.use(undefined, async function (err) {
  const authStore = useAuthStore()
  if (err.response.status === 401 && err.response.data.message === "JWT expired") {
    await authStore.loginRequest()

    err.response.config.headers = {
      ...err.response.config.headers,
      ...tokenHeader(authStore.getToken ?? ""),
    }
    return apiClient.request(err.response.config)
  }
  throw err
})

routerStoreInit(router)
router.beforeEach(async (to) => {
  const authStore = useAuthStore()

  if (to.matched.some((record) => record.meta.protected)) {
    if (isTokenEmptyOrExpired(authStore.getToken ?? "")) {
      try {
        await authStore.loginRequest()

        // if (import.meta.MODE === "production") {
        //   initNotifications(settings);
        // }
        return
      } catch (e) {
        authStore.goto = to
        return false
      }
    }
    // if (import.meta.MODE === "production") {
    //   initNotifications(settings);
    // }
  }
})

applyAclGuard(router)

const pinia = createPinia()

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(ElementPlus)
app.config.globalProperties.$developer = localStorage.getItem("d3v3l0p3r") === "1"
authStorageSync()

i18init()

app.use(I18NextVue, { i18next })

// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component);
// }

app.mount("#app")
