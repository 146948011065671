<template>
  <div>
    <template v-for="(trackingOptionValues, trackingOptionName) in trackingOptionsInternal" :key="trackingOptionName">
      <el-row>
        <el-col>
          <template v-if="isSingularSwitch(trackingOptionValues)">
            <el-form-item :label="$t(`trackingOption.${trackingOptionName}_name`)">
              <el-switch
                :model-value="modelValue?.[trackingOptionName] ?? null"
                @update:model-value="setVal(trackingOptionName, $event)"
                :inactive-value="getSingularValue(trackingOptionValues)"
                :disabled="true"></el-switch>
              {{ $t(`trackingOption.${trackingOptionName}`) }}
            </el-form-item>
          </template>
          <template v-else-if="isSingularSelect(trackingOptionValues)">
            <el-form-item :label="$t(`trackingOption.${trackingOptionName}_name`)">
              <el-select
                :model-value="modelValue[trackingOptionName]"
                @update:model-value="setVal(trackingOptionName, $event)"
                placeholder=""
                :clearable="false">
                <el-option
                  v-for="trackingOptionValue in trackingOptionValues"
                  :key="trackingOptionValue.value"
                  :label="$t(`trackingOption.${trackingOptionName}.${trackingOptionValue.value}`)"
                  :value="trackingOptionValue.value"
                  :disabled="true"></el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-else-if="isBoolean(trackingOptionValues)">
            <el-form-item :label="$t(`trackingOption.${trackingOptionName}_name`)">
              <el-switch
                :model-value="modelValue?.[trackingOptionName] ?? null"
                @update:model-value="setVal(trackingOptionName, $event)"
                :inactive-value="getBooleanInactiveValue(trackingOptionValues)"
                :active-value="getBooleanActiveValue(trackingOptionValues)"
                :disabled="disabled"></el-switch>
              {{ $t(`trackingOption.${trackingOptionName}`) }}
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="$t(`trackingOption.${trackingOptionName}_name`)">
              <el-select
                :model-value="modelValue[trackingOptionName]"
                @update:model-value="setVal(trackingOptionName, $event)"
                placeholder=""
                :clearable="false">
                <el-option
                  v-for="trackingOptionValue in trackingOptionValues"
                  :key="trackingOptionValue.value"
                  :label="$t(`trackingOption.${trackingOptionName}.${trackingOptionValue.value}`)"
                  :value="trackingOptionValue.value"
                  :disabled="disabled"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue"
type TrackingOptionValue = { value: string | null; default: boolean }

export default defineComponent({
  name: "TrackingOptionsSelector",
  i18nOptions: {
    namespaces: ["common"],
  },
  emits: ["update:modelValue"],
  mounted() {
    if (!Object.entries(this.modelValue).length) {
      this.emitDefaults() // Loads defaults on ContentPiece view
    }
  },
  methods: {
    setVal(key: string, val: TrackingOptionValue) {
      this.$emit(
        "update:modelValue",
        Object.fromEntries(Object.entries({ ...this.modelValue, [key]: val }).filter(([, v]) => v)),
      )
    },
    isSingularSwitch(trackingOptionValues: TrackingOptionValue[]) {
      return trackingOptionValues.length === 1 && trackingOptionValues.map((i) => i.value).includes(null)
    },
    isSingularSelect(trackingOptionValues: TrackingOptionValue[]) {
      return trackingOptionValues.length === 1 && !trackingOptionValues.map((i) => i.value).includes(null)
    },
    getSingularValue(trackingOptionValues: TrackingOptionValue[]) {
      return trackingOptionValues.map((i) => i.value)[0]
    },
    isBoolean(trackingOptionValues: TrackingOptionValue[]) {
      return trackingOptionValues.length === 2 && trackingOptionValues.map((i) => i.value).includes(null)
    },
    getBooleanActiveValue(trackingOptionValues: TrackingOptionValue[]) {
      return trackingOptionValues.map((i) => i.value).filter((i) => i)[0]
    },
    getBooleanInactiveValue(trackingOptionValues: TrackingOptionValue[]) {
      return trackingOptionValues.map((i) => i.value).filter((i) => !i)[0] ?? undefined
    },
    emitDefaults() {
      if (this.trackingOptionsInternal) {
        Object.entries(this.trackingOptionsInternal).forEach(([key, vals]) => {
          vals.forEach((val) => {
            if (val["default"]) {
              this.modelValue[key] = val["value"] // eslint-disable-line vue/no-mutating-props
            }
          })
        })
        this.$emit("update:modelValue", Object.fromEntries(Object.entries({ ...this.modelValue }).filter(([, v]) => v)))
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    trackingOptions: {
      type: Object as PropType<Record<string, Record<string, TrackingOptionValue[]>>>,
      required: true,
    },
    isQuiz: {
      type: Boolean,
      default: () => false,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    trackingOptionsInternal(): Record<string, TrackingOptionValue[]> {
      return this.trackingOptions[this.isQuiz ? "quiz" : "article"]
    },
  },
  watch: {
    trackingOptionsInternal: function () {
      if (!Object.entries(this.modelValue).length) {
        this.emitDefaults() // Loads defaults on ContentItem view
      }
    },
    isQuiz: function () {
      this.emitDefaults()
    },
  },
})
</script>

<style scoped></style>
