import { getList } from "@/services/rest/assets"
import { defineStore } from "pinia"
import type { Asset, AssetId } from "@/store/types"

interface State {
  assetsList: Asset[]
}

export default defineStore("assets", {
  state: (): State => ({
    assetsList: [],
  }),
  getters: {
    getAssetsList: (state) => state.assetsList,
    getAssetById:
      (state) =>
      (id: AssetId): Asset | undefined =>
        state.assetsList.find((item) => item.id === id),
  },
  actions: {
    async loadAssetsList() {
      const result = await getList()
      this.setAssetsList(result)
    },
    setAssetsList(data: any) {
      this.assetsList = data
    },
  },
})
