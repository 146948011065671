<template>
  <el-alert class="error_box" v-if="error" :title="title" type="error" :closable="false">
    <template v-slot:title>
      {{ title }}
      <a href="#" @click.prevent="detailsVisible = !detailsVisible">({{ $t("error.toggle_details") }})</a>
    </template>
    <fieldset class="details" v-if="detailsVisible">
      <legend>{{ $t("error.details") }}</legend>
      <code>
        <pre>{{ JSON.stringify(details, null, 4) }}</pre>
      </code>
    </fieldset>
  </el-alert>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "DetailedError",
  props: {
    type: String,
    error: [Object, Error],
  },
  data() {
    return {
      detailsVisible: false,
    }
  },
  computed: {
    title() {
      return this.$t(
        `error.messages.${this.message.replace(/ /g, "_")}`,
        this.message || this.$t(`error.messages.unknown_error`),
      )
    },
    message() {
      if (this.type === "req") {
        return (
          this.error.response?.data?.message_translated ||
          this.error.response?.data?.hint ||
          this.error.response?.data?.message ||
          "unknown_request_error"
        )
      }
      if (this.type === "model") {
        return this.error.message
      }
      return null
    },
    details() {
      if (this.type === "req") {
        return {
          request: {
            url: this.error.config?.url,
            baseUrl: this.error.config?.baseUrl,
            method: this.error.config?.method,
            params: this.error.config?.params,
            data: this.error.config?.data,
          },
          response: {
            data: this.error.response?.data,
            status: this.error.response?.status,
            headers: this.error.response?.headers,
          },
        }
      }
      if (this.type === "model") {
        return this.error
      }
      return null
    },
  },
})
</script>
