<template>
  <el-main
    class="themain"
    :element-loading-text="busyWithComputed ? busyWithComputed.map((w) => $t(w)).join(', ') : ''"
    v-loading.fullscreen.lock="isBusy"
    element-loading-background="rgba(255, 255, 255, 0.5)">
    <el-affix>
      <el-row class="subnavi" v-if="showSubnavi">
        <el-col :span="subnaviLeftSpan" style="text-align: left">
          <slot name="subnavi-left"></slot>
        </el-col>
        <el-col :span="subnaviCenterSpan" style="text-align: center">
          <slot name="subnavi-center"></slot>
        </el-col>
        <el-col :span="subnaviRightSpan" style="text-align: right">
          <slot name="subnavi-right"></slot>
        </el-col>
      </el-row>

      <transition name="fade">
        <slot name="filters"></slot>
      </transition>
    </el-affix>
    <el-row>
      <el-col>
        <slot name="content"></slot>
      </el-col>
    </el-row>

    <slot name="default"></slot>
  </el-main>
</template>

<script lang="ts">
import { mapState } from "pinia"
import useInfobarStore from "@/store/infobar"
import { defineComponent } from "vue"
import useBusyStore from "@/store/busy"
// busy.loading_content_items, busy.loading_text_classes, busy.loading_content_formats
export default defineComponent({
  name: "Page",
  i18nOptions: {
    namespaces: ["", "busy"],
  },
  props: {
    busyWith: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    ...mapState(useInfobarStore, {
      infobarHeight: "height",
    }),
    ...mapState(useBusyStore, {
      storeBusy: "busy",
      busyList: "busyList",
      storeBusyWith() {
        return this.busyList?.map((busy) => busy.with) || null
      },
    }),
    isBusy() {
      return this.busyWithComputed.length > 0
    },
    busyWithComputed() {
      return this.busyWith ? [this.busyWith] : this.storeBusyWith
    },
    showSubnavi() {
      return (
        this.$slots["subnavi-left"] !== undefined ||
        this.$slots["subnavi-center"] !== undefined ||
        this.$slots["subnavi-right"] !== undefined
      )
    },
    subnaviLeftSpan() {
      switch (true) {
        case this.$slots["subnavi-left"] !== undefined && this.$slots["subnavi-center"] !== undefined:
          return 8
        case this.$slots["subnavi-left"] !== undefined && this.$slots["subnavi-right"] !== undefined:
          return 12
        case this.$slots["subnavi-left"] !== undefined:
          return 24
        default:
          return 0
      }
    },
    subnaviCenterSpan() {
      switch (true) {
        case this.$slots["subnavi-center"] !== undefined:
          return 8
        default:
          return 0
      }
    },
    subnaviRightSpan() {
      switch (true) {
        case this.$slots["subnavi-left"] !== undefined && this.$slots["subnavi-center"] !== undefined:
          return 8
        case this.$slots["subnavi-left"] !== undefined && this.$slots["subnavi-center"] === undefined:
          return 12
        case this.$slots["subnavi-left"] === undefined && this.$slots["subnavi-center"] === undefined:
          return 24
        default:
          return 0
      }
    },
  },
})
</script>

<style scoped>
.themain {
  overflow: hidden;
}
</style>
