import type { NumRange, NumRangeString } from "@/store/types"

/**
 * Async version of `Array.from`.
 */
export async function arrayFrom(iter: any) {
  const isAsyncIterator = !!iter[Symbol.asyncIterator]
  if (!isAsyncIterator) {
    return Array.from(iter)
  }
  const arr = []
  for await (const v of iter) {
    arr.push(v)
  }
  return arr
}

export function unique(iter: any[]) {
  return Array.from(new Set(iter))
}

export function isObject(x: any) {
  return x !== null && typeof x === "object"
}

/**
 * @template K, L, V, W
 * @param {object<K, V>} o
 * @param {(K, V) => [L, W]} mapper
 * @return {object<L, W>}
 */
export function objectMap<K extends string | number | symbol, V, RK extends string | number | symbol, RV>(
  o: Record<K, V>,
  mapper: (v: [K, V], i: number, a: [K, V][]) => [RK, RV],
): Record<RK, RV> {
  return Object.fromEntries((Object.entries(o) as [K, V][]).map(mapper)) as Record<RK, RV>
}

/**
 * Seal and freeze and object recursively in-place.
 *
 * Makes the given object for all intents and purposes immutable.
 *
 * @return Object the finalized object, same as o
 * @param o
 */
export function finalize<T extends object>(o: T): T {
  if (!isObject(o)) {
    throw new TypeError("Object required.")
  }
  for (const v of Object.values(o).filter(isObject)) {
    finalize(v)
  }
  return Object.freeze(Object.seal(o))
}

export const removeKeysWithUndefinedValue = (obj: Object) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined))

export function strOrFirstOfStrArr(s: string | string[]): string {
  if (Array.isArray(s)) return s[0]
  return s
}
type SetterGetter<T = any> = { get(): T; set(v: T): void }
export function generateGettersAndSetters<K, R = { [k in keyof K]: SetterGetter }>(
  fields: (keyof K)[],
  model: string,
  scb: (field: any, value: any) => void = () => {},
): R {
  return fields.reduce(
    (all, field) => ({
      ...all,
      [field]: {
        get(this: any): any {
          return (this[model] as K)[field]
        },
        set(this: any, value: any) {
          this[model] = { ...this[model], [field]: value }
          scb.call(this, field, value)
        },
      },
    }),
    {} as R,
  )
}

export const stringifyQueryParams = (query: Object) => {
  const filtered = Object.entries(query).filter(([, v]) => String(v)?.length)
  return filtered.length ? JSON.stringify(Object.fromEntries(filtered)) : undefined
}

export const isTokenEmptyOrExpired = (token: string | null) => {
  if (!token) {
    return true
  }
  const [, claimsEncoded] = token.split(".")
  const claims = JSON.parse(atob(claimsEncoded))
  const { exp } = claims

  const now = new Date().getTime() / 1000

  return exp < now
}

export const svg = (name: string, width = 14, height = 14) => {
  const icon = document.createElementNS("http://www.w3.org/2000/svg", "svg")

  icon.classList.add("icon", "icon--" + name)
  icon.setAttribute("width", width + "px")
  icon.setAttribute("height", height + "px")
  icon.innerHTML = `<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#${name}"></use>`

  return icon
}

export const removeProtocol = (url: string) => url.replace(/^https?:\/\//, "//")

const dateStr = (date: string) => {
  if (!date) {
    return null
  }
  if (date.match(/\d{4}-\d{2}-\d{2}$/)) {
    return `${date}`
  }
  if (date.match(/[+-]\d{2}:\d{2}$/)) {
    return `${date}`
  }
  if (date.endsWith("Z")) {
    return `${date}`
  }
  return `${date}Z`
}

export const date = (date: string) => {
  const str = dateStr(date)
  return str ? new Date(str) : null
}

export const formatCurrency = (value: number, currency = "EUR", locale = "de-DE") => {
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(value)
}

export const replaceDomain = (url: string, domain: string) =>
  url.replace(/^(https?:)?(\/\/)([^/]+)(.*)$/, `$1$2${domain}$4`)

type Miliseconds = number

export const delay = async (time: Miliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export function validateIbanChecksum(iban: string) {
  const ibanStripped = iban.replace(/[^A-Z0-9]+/gi, "").toUpperCase()

  const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/)

  if (!m) return false

  const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, function (ch): string {
    return "" + (ch.charCodeAt(0) - 55)
  })

  const mod97 = numbericed.match(/\d{1,7}/g)?.reduce(function (total, curr): string {
    return "" + (Number(total + curr) % 97)
  }, "")

  return Number(mod97) === 1
}

export const dateValidRange = (
  rule: { min?: () => Date; max?: () => Date; message: string },
  value: Date,
  callback: (arg?: Error) => void,
) => {
  const { min, max } = rule
  if (value < (min?.() || new Date(0)) || value > (max?.() || new Date("2100"))) {
    callback(new Error(rule.message))
  }
  callback()
}

export const proxy = function (obj: object) {
  return new Proxy(obj, {
    get: function (target, prop) {
      if (!["_isVue", Symbol.toStringTag].includes(prop) && !Object.hasOwnProperty.call(target, prop)) {
        console.error("Trying to read undefined property", prop) // eslint-disable-line no-console
      }
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      return Reflect.get(...arguments)
    },
  })
}

export function throwErr(err: Error): never {
  throw err
}

export const fromRangeString = (range: NumRangeString): NumRange => {
  const m = range.match(/\[(\d*),(\d*)\)/)
  if (m) {
    return [m[1] ? parseInt(m[1], 10) : -Infinity, m[2] ? parseInt(m[2], 10) : Infinity]
  }
  return [null, null]
}

export const toRangeString = (range: NumRange): NumRangeString => {
  return `[${!Number.isFinite(range[0]) ? "" : range[0] ?? ""},${!Number.isFinite(range[1]) ? "" : range[1] ?? ""})`
}
