import i18next from "i18next"
import { defineStore } from "pinia"
import locale from "@/locale"

type Lang = "en" | "de"
interface State {
  lang: Lang
}

export default defineStore("lang", {
  state: (): State => ({
    lang: (localStorage.getItem("lang") ?? "de") as Lang,
  }),
  getters: {
    getLang: (state) => state.lang,
    locale: (state) => ({
      name: locale[state.lang].name,
      el: locale[state.lang].el,
    }),
  },
  actions: {
    async changeLanguage(lang: Lang) {
      await i18next.changeLanguage(lang)
      this.setLang(lang)
    },

    setLang(lang: string) {
      localStorage.setItem("lang", lang)
      this.lang = lang as Lang
    },
  },
})
