import { defineStore } from "pinia"

interface State {
  height: number
}

export default defineStore("infobar", {
  state: (): State => ({
    height: 0,
  }),
  getters: {
    isVisible: (state) => state.height !== 0,
  },
  actions: {
    setInfobarHeight(height: number) {
      this.height = height
    },
  },
})
