import {
  acceptProfileHeader,
  buildArgs,
  contentProfileHeader,
  countHeader,
  get,
  post,
  patch,
  del,
  singularHeader,
  buildSorting,
  type SortingDirectives,
  type SelectColumnList,
} from "@/services/rest/index"
import type {
  DataList,
  Department,
  DepartmentId,
  ContentItem,
  JobId,
  ContributorId,
  TaskId,
  ContentId,
  ContentVisibility,
  Contributor,
  Workflow,
  TextClass,
  ContentFormat,
  Company,
  ContentPieceId,
} from "@/store/types"
import { emptyContentItem, emptyUUID } from "@/store/workflows"
export const WORKFLOWS_PUBLIC_SCHEMA = "workflows_public"

export const TEXT_CLASS_ID_CUSTOM = "custom"

export function getContentItems(args: any) {
  return get<DataList<ContentItem>>("/content_items", buildArgs(args), {
    ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    ...countHeader(),
  })
}

function prepareContentItemModelForSave(model: ContentItem, bulk: boolean): ContentItem {
  model = Object.fromEntries(
    Object.entries(model).filter(([k]) => Object.keys(emptyContentItem).includes(k)),
  ) as ContentItem

  if (model.id === emptyUUID) {
    model.id = null
  }
  if (model.author_fee === 0) {
    model.author_fee = null
  }
  if (model.lector_fee === 0) {
    model.lector_fee = null
  }
  if (model.text_class_id === TEXT_CLASS_ID_CUSTOM) {
    model.text_class_id = null
  }
  if (model.keyword === "") {
    model.keyword = null
  }
  if (bulk) {
    model = Object.fromEntries(
      Object.entries(model).filter(([k]) => ["asset", "department_id", "subjects"].includes(k)),
    ) as ContentItem
  } else {
    model.subject = model.subject?.trim()
    delete model.subjects
  }

  return model
}

export function requestNewContentItem(model: ContentItem) {
  const model_ = prepareContentItemModelForSave(model, false)

  const columns = [
    "id",
    "asset",
    "department_id",
    "subject",
    "keyword",
    "format_id",
    "info",
    "fastline",
    "level",
    "advertorial",
    "draft",
    "text_class_id",
    "author_fee",
    "lector_fee",
    "tracking",
    "priority",
  ]
  return post<{ id: ContentPieceId; job_id: JobId }>(
    "/rpc/request_new_content_item",
    model_,
    {
      columns: columns.join(","),
    },
    {
      ...singularHeader(),
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function requestNewContentItems(model: ContentItem) {
  const model_ = prepareContentItemModelForSave(model, true)

  const columns = ["asset", "department_id", "subjects"]

  return post(
    "/rpc/request_new_content_items",
    model_,
    { columns: columns.join(",") },
    {
      ...singularHeader(),
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function requestUpdateContentItem(model: ContentItem) {
  const model_ = prepareContentItemModelForSave(model, false)

  const columns = [
    "id",
    "asset",
    "department_id",
    "subject",
    "keyword",
    "format_id",
    "info",
    "fastline",
    "level",
    "text_class_id",
    "author_fee",
    "lector_fee",
    "priority",
  ]

  return post<{ id: ContentPieceId; job_id: JobId }>(
    "/rpc/request_update_content_item",
    model_,
    {
      columns: columns.join(","),
    },
    {
      ...singularHeader(),
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function editContentItem(model: ContentItem) {
  const model_ = prepareContentItemModelForSave(model, false)

  const columns = [
    "id",
    "asset",
    "department_id",
    "subject",
    "keyword",
    "format_id",
    "info",
    "fastline",
    "level",
    "advertorial",
    "text_class_id",
    "author_fee",
    "lector_fee",
    "tracking",
    "priority",
  ]

  return post(
    "/rpc/edit_content_item",
    model_,
    {
      columns: columns.join(","),
    },
    {
      ...singularHeader(),
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function reopenContentItem(job_id: JobId) {
  // eslint-disable-line camelcase
  return post(
    "/rpc/reopen_content_item",
    { job_id },
    {},
    {
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function setDueDate(task_id: TaskId, due_date: Date) {
  // eslint-disable-line camelcase
  return post(
    "/rpc/set_due_date",
    { task_id, due_date },
    {},
    {
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function assignAuthor(job_id: JobId, contributor_id: ContributorId) {
  // eslint-disable-line camelcase
  return post(
    "/rpc/assign_author",
    { job_id, contributor_id },
    {},
    {
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function assignLector(job_id: JobId, contributor_id: ContributorId) {
  // eslint-disable-line camelcase
  return post(
    "/rpc/assign_lector",
    { job_id, contributor_id },
    {},
    {
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function changeVisibility(content_id: ContentId, visibility: ContentVisibility) {
  // eslint-disable-line camelcase
  return post(
    "/rpc/change_visibility",
    { content_id, visibility },
    {},
    {
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function updateContributor(args: Contributor) {
  const { id, level, ...rest } = args
  const args_ = Object.fromEntries(
    Object.entries(rest).map(([k, v]) => [k, ["tax_rate"].includes(k) || v !== "" ? v : null]),
  )
  return patch("/contributors", args_, buildArgs({ id }), {
    ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
  })
}

export function searchContributors<D>(args: any) {
  return get<DataList<D>>("/contributors_search", buildArgs(args), {
    ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    ...countHeader(),
  })
}

export function loadContributors(args: any) {
  return get<DataList<Contributor>>("/contributors_list", buildArgs(args), {
    ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    ...countHeader(),
  })
}

export function createDepartment(department: Department) {
  return post(
    "/departments",
    department,
    {},
    {
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}

export function updateDepartment(args: Department) {
  const { id, ...rest } = args
  return patch("/departments", rest, buildArgs({ id }), {
    ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
  })
}

export function deleteDepartment(id: DepartmentId) {
  return del("/departments", buildArgs({ id }), {
    ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
  })
}

export function getDepartments(args: any) {
  return get<DataList<Department>>("/departments", buildArgs(args), {
    ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    ...countHeader(),
  })
}

export function createTextClass(args: any) {
  return post<DataList<TextClass>>(
    "/text_classes",
    args,
    {},
    {
      ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
      ...countHeader(),
    },
  )
}

export function updateTextClass(args: any) {
  const { id, ...rest } = args
  return patch("/text_classes", rest, buildArgs({ id }), {
    ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
  })
}

export function deleteTextClass(args: any) {
  return del("/text_classes", buildArgs(args), {
    ...contentProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
  })
}

export function getTextClasses({ sort, ...rest }: { sort?: SortingDirectives } & Partial<SelectColumnList>) {
  return get<DataList<TextClass>>(
    "/text_classes",
    {
      ...buildArgs(rest),
      ...buildSorting(sort),
    },
    {
      ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
      ...countHeader(),
    },
  )
}

export function getWorkflows(args: any) {
  return get<Workflow[]>("/workflows", buildArgs(args), {
    ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
  })
}

export function createContentFormat(args: any) {
  return post("/content_formats", args, {}, {})
}

export function updateContentFormat(args: any) {
  const { id, ...rest } = args
  const { asset, name, url, content_type, tracking, settings } = rest
  return patch(
    "/content_formats",
    { asset, name, url, content_type, tracking, settings },
    buildArgs({ id, columns: ["asset", "name", "url", "content_type", "tracking", "settings"] }),
    {},
  )
}

export function deleteContentFormat(args: any) {
  return del("/content_formats", buildArgs(args), {})
}

export function getContentFormats({ sort, ...args }: { sort?: SortingDirectives }) {
  return get<DataList<ContentFormat>>(
    "/content_formats",
    {
      ...buildArgs(args),
      ...buildSorting(sort),
    },
    {
      ...countHeader(),
    },
  )
}

export function getCompanies(args: any) {
  return get<DataList<Company>>("/companies", buildArgs(args), {
    ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    ...countHeader(),
  })
}

export function getTrackingOptions(args = {}) {
  return get<{ dimension: string; value: string; default: boolean; content_type: string }[]>(
    "/tracking",
    buildArgs(args),
    {
      ...acceptProfileHeader(WORKFLOWS_PUBLIC_SCHEMA),
    },
  )
}
