<template>
  <page>
    <template #subnavi-left>
      <el-button :disabled="(!isEditable && !updateRequestMode) || !!bulkResult.length" @click="handleSave">{{
        $t(saveBtnLabel)
      }}</el-button>
      <el-button :disabled="!canReopenContentItem" @click="handleReopen">{{ $t("form.reopen") }}</el-button>
      <el-button
        v-if="!isEditable && !updateRequestMode"
        :disabled="!isEditableAuthor && !isEditableLector"
        @click="handleReassign"
        >{{ $t("form.reassign") }}</el-button
      >
      <el-button @click="handleBtnGotoArticle(getContentItem)" v-if="getContentItem.source_url">{{
        $t("form.goto_article")
      }}</el-button>
      <el-button @click="goBack">{{ $t("form.close") }}</el-button>
    </template>
    <template #subnavi-center>
      <el-button @click="bulk = !bulk" :disabled="!newRequestMode || !!bulkResult.length">
        <template v-if="bulk">{{ $t("form.normal_mode") }}</template>
        <template v-else>{{ $t("form.bulk_mode") }}</template>
      </el-button>
    </template>
    <template #subnavi-right>
      <fixtures-content-item v-model="model" />
      <el-button
        v-if="$options.VISIBILITY_PUBLISHED === getContentItem.visibility"
        :disabled="!canUnpublish"
        @click="handleUnpublish"
        >{{ $t("form.unpublish") }}</el-button
      >
      <el-button
        v-if="$options.VISIBILITY_DRAFT === getContentItem.visibility"
        :disabled="!canPublish"
        @click="handlePublish"
        >{{ $t("form.publish") }}</el-button
      >
      <el-button :disabled="!canDelete" @click="handleDelete" type="danger">{{ $t("form.delete") }}</el-button>
    </template>
    <template #content>
      <el-row v-show="initialized">
        <el-col>
          <detailed-error class="mb20" type="req" :error="requestError" />
          <el-form ref="form" :rules="formRules" :model="model" :validate-on-rule-change="false" label-position="top">
            <el-row :gutter="20">
              <el-col :span="7">
                <fieldset>
                  <legend>{{ $t("form.fieldset_classification") }}</legend>

                  <el-form-item prop="asset" :label="$t('form.asset')">
                    <el-select
                      :disabled="!isEditableAsset"
                      v-model="model.asset"
                      :placeholder="$t('form.placeholder.asset')"
                      :style="{ display: 'block' }">
                      <el-option
                        v-for="asset in getAssetsList"
                        :key="asset.id"
                        :label="asset.name"
                        :value="asset.id"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="department_id" :label="$t('form.department')">
                    <el-select
                      :disabled="!isEditableDepartment"
                      v-model="model.department_id"
                      :placeholder="$t('form.placeholder.department')"
                      :style="{ display: 'block' }">
                      <el-option
                        v-for="department in getDepartments.data"
                        :key="department.id"
                        :label="department.cost_number"
                        :value="department.id"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item :label="$t('form.status')">
                    {{ $t(`statuses.${model.status}`) }}
                  </el-form-item>
                </fieldset>
              </el-col>

              <el-col :span="10" v-if="!bulk">
                <fieldset>
                  <legend>{{ $t("form.fieldset_details") }}</legend>
                  <el-form-item prop="subject" :label="$t('form.subject')">
                    <el-input
                      :disabled="!isEditableSubject"
                      :placeholder="$t('form.placeholder.subject')"
                      v-model="model.subject"></el-input>
                  </el-form-item>

                  <el-form-item prop="keyword" :label="$t('form.keyword')">
                    <el-input
                      :disabled="!isEditableKeyword"
                      :placeholder="$t('form.placeholder.keyword')"
                      v-model="model.keyword"></el-input>
                  </el-form-item>

                  <el-form-item prop="content_type" :label="$t('form.content_type')">
                    <el-select
                      :disabled="!isEditableFormat"
                      v-model="contentType"
                      :placeholder="$t('form.placeholder.content_type')"
                      :style="{ display: 'block' }">
                      <el-option
                        v-for="contentType in contentTypes"
                        :key="contentType"
                        :label="$t(`form.content_types.${contentType}`)"
                        :value="contentType"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="format_id" :label="$t('form.format')">
                    <el-select
                      :disabled="!isEditableFormat"
                      v-model="model.format_id"
                      :placeholder="$t('form.placeholder.format')"
                      :style="{ display: 'block' }"
                      filterable>
                      <el-option
                        v-for="format in contentFormats"
                        :key="format.id"
                        :label="format.name"
                        :value="format.id"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="info" :label="$t('form.info')">
                    <el-input
                      :disabled="!isEditableInfo"
                      type="textarea"
                      :placeholder="$t('form.placeholder.info')"
                      v-model="model.info"></el-input>
                  </el-form-item>

                  <el-form-item prop="fastline" :label="$t('form.fastline')">
                    <el-switch :disabled="!isEditableFastline" v-model="model.fastline"></el-switch>
                  </el-form-item>

                  <el-form-item prop="level" :label="$t('form.level')">
                    <el-switch
                      :disabled="!isEditableLevel"
                      v-model="model.level"
                      :active-value="2"
                      :inactive-value="0"></el-switch>
                  </el-form-item>

                  <el-form-item v-if="advertSwitchVisible" prop="advertorial" :label="$t('form.advertorial')">
                    <el-switch :disabled="!isEditableAdvertorial" v-model="model.advertorial"></el-switch>
                  </el-form-item>

                  <el-form-item v-if="draftSwitchVisible" prop="draft" :label="$t('form.draft')">
                    <el-switch :disabled="!isEditableDraft" v-model="model.draft"></el-switch>
                  </el-form-item>
                  <el-form-item v-if="trackingOptionsVisible" prop="tracking" :label="$t('form.tracking')">
                    <tracking-options-selector
                      :tracking-options="getTrackingOptions"
                      :disabled="!isEditableTracking"
                      :is-quiz="isQuiz"
                      v-model="model.tracking"></tracking-options-selector>
                  </el-form-item>
                  <el-form-item prop="text_class_id" :label="$t('form.text_class')">
                    <el-radio-group :disabled="!isEditableTextClass" v-model="model.text_class_id">
                      <el-space direction="vertical" alignment="start" :size="0">
                        <el-radio v-for="textClass in getTextClasses.data" :label="textClass.id" :key="textClass.id">
                          {{ textClass.name }}
                          (A:
                          <currency-formatted
                            v-if="updateRequestMode"
                            :value="textClass.author_update_fee"></currency-formatted>
                          <currency-formatted v-else :value="textClass.author_fee"></currency-formatted>, L:
                          <currency-formatted
                            v-if="updateRequestMode"
                            :value="textClass.lector_update_fee"></currency-formatted>
                          <currency-formatted v-else :value="textClass.lector_fee"></currency-formatted>
                          )
                        </el-radio>
                        <el-radio :label="TEXT_CLASS_ID_CUSTOM">{{ $t("form.text_class_custom") }}</el-radio>
                      </el-space>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item
                    v-if="model.text_class_id === TEXT_CLASS_ID_CUSTOM"
                    prop="author_fee"
                    :label="$t('form.author_fee')">
                    <el-input-number
                      :disabled="!isEditableAuthorFee"
                      :placeholder="$t('form.placeholder.author_fee')"
                      v-model="model.author_fee"
                      :precision="2"
                      :step="0.1"
                      :controls="false"></el-input-number>
                  </el-form-item>

                  <el-form-item
                    v-if="model.text_class_id === TEXT_CLASS_ID_CUSTOM"
                    prop="lector_fee"
                    :label="$t('form.lector_fee')">
                    <el-input-number
                      :disabled="!isEditableLectorFee"
                      :placeholder="$t('form.placeholder.lector_fee')"
                      v-model="model.lector_fee"
                      :precision="2"
                      :step="0.1"
                      :controls="false"></el-input-number>
                  </el-form-item>

                  <el-form-item v-if="priorityOptionsVisible" prop="priority" :label="$t('form.priority')">
                    <el-radio-group v-model="model.priority" :disabled="!isEditablePriority">
                      <el-space direction="vertical" alignment="start" :size="0">
                        <el-radio v-for="priority in $options.JOB_PRIORITIES" :key="priority" :label="priority">{{
                          $t(`priority.${priority}`)
                        }}</el-radio>
                      </el-space>
                    </el-radio-group>
                  </el-form-item>
                </fieldset>
              </el-col>

              <el-col :span="7" v-if="!bulk">
                <fieldset>
                  <legend>{{ $t("form.fieldset_assignment") }}</legend>

                  <el-form-item prop="author" :label="$t('form.author')">
                    <el-select
                      :disabled="!isEditableAuthor"
                      :placeholder="$t('form.placeholder.author')"
                      :remote-method="
                        searchForContributors({
                          rolesContains: [ROLE_CONTRIBUTOR],
                        })
                      "
                      :title="$t('form.author')"
                      :allow-create="false"
                      v-model="author_id"
                      style="width: 100%"
                      filterable
                      remote>
                      <el-option
                        v-for="item in getContributors.data"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="lector" :label="$t('form.lector')">
                    <el-select
                      :disabled="!isEditableLector"
                      :placeholder="$t('form.placeholder.lector')"
                      :remote-method="searchForContributors({ rolesContains: [ROLE_LECTOR] })"
                      :title="$t('form.lector')"
                      :allow-create="false"
                      v-model="lector_id"
                      style="width: 100%"
                      filterable
                      remote>
                      <el-option
                        v-for="item in getContributors.data"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </fieldset>
              </el-col>

              <el-col :span="16" v-if="bulk">
                <fieldset v-if="!bulkResult.length">
                  <legend>{{ $t("form.fieldset_subjects") }}</legend>
                  <el-form-item prop="subjects">
                    <template #label>
                      <span v-html="$t('form.fieldset_subjects_label')"></span>
                    </template>
                    <el-input
                      type="textarea"
                      :rows="10"
                      :placeholder="$t('form.placeholder.subjects')"
                      v-model="bulkDataRaw"></el-input>
                  </el-form-item>
                  <el-table :data="subjectsInternal">
                    <el-table-column :label="$t('form.table.subject')" prop="subject"></el-table-column>
                    <el-table-column :label="$t('form.table.format')" prop="format">
                      <template v-slot="{ row }">
                        <el-tooltip
                          effect="dark"
                          :content="$t('errors.unrecognized_content_format')"
                          placement="top-start">
                          <el-icon v-show="!row.format_id">
                            <warning-icon></warning-icon>
                          </el-icon>
                        </el-tooltip>
                        {{ row.format }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('form.table.info')" prop="info"></el-table-column>
                  </el-table>
                </fieldset>

                <fieldset v-if="bulkResult.length">
                  <legend>{{ $t("form.fieldset_bulk_result") }}</legend>
                  <el-form-item>
                    <el-table class="mb20" :data="bulkResult">
                      <el-table-column :label="$t('form.table.subject')" prop="subject"></el-table-column>
                      <el-table-column :label="$t('form.table.format')" prop="format">
                        <template v-slot="scope">
                          {{ scope.row.format }}
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('form.table.status')">
                        <template v-slot="scope">
                          <div v-if="scope.row.error" class="el-icon-error">
                            <el-icon>
                              <error-icon></error-icon>
                            </el-icon>
                            {{ $t(`errors.${scope.row.error.message}`) }}
                          </div>
                          <div v-else class="el-icon-success">
                            <el-icon>
                              <success-icon></success-icon>
                            </el-icon>
                            <router-link
                              :to="{
                                name: 'contentItemEdit',
                                params: { id: scope.row.id },
                              }"
                              >{{ $t("form.table.goto") }}</router-link
                            >
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-button @click="handleBtnRedo">{{ $t("form.table.redo") }}</el-button>
                  </el-form-item>
                </fieldset>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </template>
  </page>
</template>

<script lang="ts">
import Page from "@/views/Page.vue"
import { mapActions, mapState } from "pinia"
import { TEXT_CLASS_ID_CUSTOM } from "@/services/rest/workflows"
import { hasRole, ROLE_CONTRIBUTOR, ROLE_LECTOR, ROLE_SEO, ROLE_MANAGER } from "@/services/auth"
import { emptyUUID, VISIBILITY_DRAFT, VISIBILITY_PUBLISHED, VISIBILITY_DELETED, jobPriorities } from "@/store/workflows"
import DetailedError from "@/components/DetailedError.vue"
import CurrencyFormatted from "@/components/CurrencyFormatted.vue"
import { defineComponent } from "vue"
import TrackingOptionsSelector from "@/components/TrackingOptionsSelector.vue"
import CSVParser from "@/tools/CSVParser.js"
import FixturesContentItem from "@/components/fixtures/ContentItem.vue"
import {
  Warning as WarningIcon,
  CircleCloseFilled as ErrorIcon,
  CircleCheckFilled as SuccessIcon,
} from "@element-plus/icons-vue"

export const MODE_REQUEST_NEW = "request-new"
export const MODE_REQUEST_UPDATE = "request-update"
export const MODE_REQUEST_EDIT = "edit"
import useRouteStore from "@/store/route"
import useContentFormatsStore from "@/store/contentFormats"
import useWorkflowsStore from "@/store/workflows"
import useAssetsStore from "@/store/assets"
import type { ContentItem } from "@/store/types"
import type { ElForm, FormItemRule } from "element-plus"

export default defineComponent({
  name: "ContentItem",
  i18nOptions: {
    namespaces: ["common", "workflows_item_view", "workflows_common", "priorities"],
  },
  components: {
    Page,
    FixturesContentItem,
    CurrencyFormatted,
    DetailedError,
    TrackingOptionsSelector,
    WarningIcon,
    ErrorIcon,
    SuccessIcon,
  },
  props: {
    id: {
      type: String,
      default: () => emptyUUID,
    },
    asset: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: () => MODE_REQUEST_NEW,
    },
  },
  VISIBILITY_DRAFT: VISIBILITY_DRAFT,
  VISIBILITY_PUBLISHED: VISIBILITY_PUBLISHED,
  VISIBILITY_DELETED: VISIBILITY_DELETED,
  JOB_PRIORITIES: jobPriorities,
  async created() {
    this.TEXT_CLASS_ID_CUSTOM = TEXT_CLASS_ID_CUSTOM
    this.ROLE_CONTRIBUTOR = ROLE_CONTRIBUTOR
    this.ROLE_LECTOR = ROLE_LECTOR
    this.MODE_REQUEST_NEW = MODE_REQUEST_NEW

    this.resetContentItem()

    if (!this.newRequestMode) {
      await this.load()
    }

    let loadVariousData: Promise<any>[] = [this.loadAssetsList(), this.loadTextClasses(), this.loadTrackingOptions()]

    if (this.model.author_id) {
      this.author_id = this.model.author_id
      loadVariousData = [
        ...loadVariousData,
        this.searchContributors({
          id: this.model.author_id,
        }),
      ]
    }

    if (this.model.lector_id) {
      this.lector_id = this.model.lector_id
      loadVariousData = [
        ...loadVariousData,
        this.searchContributors({
          id: this.model.lector_id,
        }),
      ]
    }

    await Promise.all(loadVariousData)

    this.resetContributors()

    if (!this.model.asset && this.asset) {
      this.model.asset = this.asset
    }

    this.initialized = true
  },
  data: () => ({
    initialized: false,
    requestError: null,
    author_id: null as string | null,
    lector_id: null as string | null,
    bulk: false,
    bulkDataRaw: "Thema\tFormat\tBriefing\n",
    bulkResult: [],
    contentTypeInternal: undefined,
  }),
  computed: {
    ...mapState(useRouteStore, {
      from: "from",
    }),
    ...mapState(useContentFormatsStore, {
      contentFormatsList: "getContentFormatsList", // use getter that cleans broken data
    }),
    ...mapState(useWorkflowsStore, {
      getContentItem: "getContentItem",
      getContributors: "getContributors",
      getDepartments: "getDepartments",
      getTextClasses: "getTextClasses",
      getTrackingOptions: "getTrackingOptions",
      canEditCurrentContentItemRequest: "canEditCurrentContentItemRequest",
      canRequestUpdateContentItem: "canRequestUpdateContentItem",
      canReopenContentItem: "canReopenContentItem",
      canReassignContentItemAuthor: "canReassignContentItemAuthor",
      canReassignContentItemLector: "canReassignContentItemLector",
      canSetVisibilityDraft: "canSetVisibilityDraft",
      canSetVisibilityPublished: "canSetVisibilityPublished",
      canSetVisibilityDeleted: "canSetVisibilityDeleted",
    }),
    ...mapState(useAssetsStore, {
      getAssetsList: "getAssetsList",
    }),
    model: {
      get(): ContentItem {
        return this.getContentItem
      },
      set(data: ContentItem) {
        this.setContentItem(data)
      },
    },
    saveBtnLabel() {
      switch (this.mode) {
        case MODE_REQUEST_NEW:
          return "form.create_new_request"
        case MODE_REQUEST_UPDATE:
          return "form.create_update_request"
        default:
          return "form.save_changes"
      }
    },
    formRules() {
      let rtn: Record<string, FormItemRule[]> = {
        asset: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("errors.field_required"),
          },
        ],
        department_id: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("errors.field_required"),
          },
        ],
        subject: [
          {
            trigger: "blur",
            validator: (rule, value, cb) => {
              if (!this.bulk && !value) {
                cb(new Error(this.$t("errors.field_required")))
              } else {
                cb()
              }
            },
          },
        ],
        subjects: [
          {
            trigger: "blur",
            validator: (rule, value, cb) => {
              if (this.bulk && (!value || !value.length)) {
                cb(new Error(this.$t("errors.field_required")))
              } else {
                cb()
              }
            },
          },
        ],
        format_id: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("errors.field_required"),
          },
        ],
        priority: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("errors.field_required"),
          },
        ],
      }

      if (hasRole(ROLE_MANAGER) || (hasRole(ROLE_SEO) && this.updateRequestMode)) {
        rtn = {
          ...rtn,
          text_class_id: [
            {
              required: true,
              trigger: "blur",
              message: this.$t("errors.field_required"),
            },
          ],
          author_fee: [
            {
              trigger: "blur",
              validator: (rule, value, cb) => {
                if (this.model.text_class_id === TEXT_CLASS_ID_CUSTOM && !value) {
                  cb(new Error(this.$t("errors.field_required")))
                } else {
                  cb()
                }
              },
            },
          ],
          lector_fee: [
            {
              trigger: "blur",
              validator: (rule, value, cb) => {
                if (this.model.text_class_id === TEXT_CLASS_ID_CUSTOM && !value) {
                  cb(new Error(this.$t("errors.field_required")))
                } else {
                  cb()
                }
              },
            },
          ],
        }
      }

      if (this.updateRequestMode) {
        rtn = {
          ...rtn,
          info: [
            {
              required: true,
              trigger: "blur",
              message: this.$t("errors.field_required"),
            },
          ],
        }
      }

      return rtn
    },
    isEditable() {
      return this.newRequestMode || this.canEditCurrentContentItemRequest
    },
    isEditableAsset() {
      return (
        this.isEditable && (this.newRequestMode || this.getContentItem.job_type === "create") && !this.bulkResult.length
      )
    },
    isEditableDepartment() {
      return (
        this.isEditable && (this.newRequestMode || this.getContentItem.job_type === "create") && !this.bulkResult.length
      )
    },
    isEditableSubject() {
      return this.isEditable && (this.newRequestMode || this.getContentItem.job_type === "create")
    },
    isEditableKeyword() {
      return this.isEditable && (this.newRequestMode || this.getContentItem.job_type === "create")
    },
    isEditableFormat() {
      return this.isEditable || this.updateRequestMode
    },
    isEditableInfo() {
      return this.isEditable || this.updateRequestMode
    },
    isEditableFastline() {
      return this.isEditable || this.updateRequestMode
    },
    isEditableLevel() {
      return (
        (hasRole(ROLE_MANAGER) || (this.updateRequestMode && hasRole(ROLE_SEO))) &&
        (this.isEditable || this.updateRequestMode)
      )
    },
    isEditableAdvertorial() {
      return this.isEditable
    },
    isEditableDraft() {
      return this.isEditable || this.updateRequestMode
    },
    isEditableTracking() {
      return this.isEditable
    },
    isEditableTextClass() {
      return (
        (hasRole(ROLE_MANAGER) || (this.updateRequestMode && hasRole(ROLE_SEO))) &&
        (this.isEditable || this.updateRequestMode)
      )
    },
    isEditableAuthorFee() {
      return (
        (hasRole(ROLE_MANAGER) || (this.updateRequestMode && hasRole(ROLE_SEO))) &&
        (this.isEditable || this.updateRequestMode)
      )
    },
    isEditableLectorFee() {
      return (
        (hasRole(ROLE_MANAGER) || (this.updateRequestMode && hasRole(ROLE_SEO))) &&
        (this.isEditable || this.updateRequestMode)
      )
    },
    isEditableAuthor() {
      return (
        (hasRole(ROLE_MANAGER) || (this.updateRequestMode && hasRole(ROLE_SEO))) &&
        (this.newRequestMode || this.updateRequestMode || this.canReassignContentItemAuthor)
      )
    },
    isEditableLector() {
      return (
        (hasRole(ROLE_MANAGER) || (this.updateRequestMode && hasRole(ROLE_SEO))) &&
        (this.newRequestMode || this.updateRequestMode || this.canReassignContentItemLector)
      )
    },
    isEditablePriority() {
      return this.isEditable
    },
    newRequestMode() {
      return this.mode === MODE_REQUEST_NEW
    },
    updateRequestMode() {
      return this.mode === MODE_REQUEST_UPDATE
    },
    editRequestMode() {
      return this.mode === MODE_REQUEST_EDIT
    },
    subjectsInternal: {
      get() {
        return this.model.subjects.map((bulkItem) => {
          bulkItem["format_id"] = null
          this.contentFormatsList.forEach((cf) => {
            if (bulkItem["format"] === cf.name) {
              bulkItem["format_id"] = cf.id
            }
          })
          return bulkItem
        })
      },
      set(data) {
        const bulkData = []
        if (data.length) {
          const parseResult = CSVParser.parse(data, true)
          parseResult.dataGrid.forEach((row) => {
            const bulkItem = {}
            parseResult.headerNames.forEach((header, index) => {
              if (header && row?.[index]) {
                if (new RegExp("thema", "i").test(header)) {
                  bulkItem["subject"] = row[index]
                } else if (new RegExp("format", "i").test(header)) {
                  bulkItem["format"] = row[index]
                  bulkItem["format_id"] = null
                } else if (new RegExp("briefing", "i").test(header)) {
                  bulkItem["info"] = row[index]
                }
              }
            })
            bulkData.push(bulkItem)
          })
        }
        this.model.subjects = bulkData
      },
    },
    canPublish() {
      return this.canSetVisibilityPublished
    },
    canUnpublish() {
      return this.canSetVisibilityDraft
    },
    canDelete() {
      return this.canSetVisibilityDeleted
    },
    advertSwitchVisible() {
      return this.newRequestMode || (this.editRequestMode && this.getContentItem.job_type === "create")
    },
    draftSwitchVisible() {
      return this.newRequestMode && hasRole(ROLE_MANAGER)
    },
    trackingOptionsVisible() {
      return (
        (this.newRequestMode || (this.editRequestMode && this.getContentItem.job_type === "create")) &&
        hasRole(ROLE_MANAGER)
      )
    },
    priorityOptionsVisible() {
      return (
        (this.newRequestMode || (this.editRequestMode && this.getContentItem.job_type === "create")) &&
        hasRole(ROLE_MANAGER)
      )
    },
    isQuiz() {
      if (!this.model.format_id) {
        return false
      }
      return (
        this.contentFormatsList.filter((item) => item.id === this.model.format_id)?.[0]?.["content_type"] === "quiz"
      )
    },
    contentTypes() {
      return this.contentFormatsList
        .map(({ content_type }) => (content_type ? content_type : "article"))
        .reduce((a, i) => (!a.includes(i) ? [...a, i] : a), []) // uniqe list
    },
    contentType: {
      get() {
        const { format_id } = this.model
        const contentFormat = this.contentFormatsList.filter(({ id }) => id === format_id)
        if (contentFormat[0] && contentFormat[0].content_type) {
          return contentFormat[0].content_type
        }
        return this.contentTypeInternal
      },
      set(newVal) {
        this.contentTypeInternal = newVal
        this.model.format_id = null
      },
    },
    contentFormats() {
      return this.contentFormatsList.filter(({ content_type }) =>
        this.contentType ? content_type === this.contentType : true,
      )
    },
  },
  methods: {
    ...mapActions(useAssetsStore, {
      loadAssetsList: "loadAssetsList",
    }),
    ...mapActions(useContentFormatsStore, {
      loadAllContentFormatsForAsset: "loadAllContentFormatsForAsset",
    }),
    ...mapActions(useWorkflowsStore, {
      loadContentItems: "loadContentItems",
      searchContributors: "searchContributors",
      loadDepartments: "loadDepartments",
      loadTextClasses: "loadTextClasses",
      requestNewContentItem: "requestNewContentItem",
      requestNewContentItems: "requestNewContentItems",
      requestUpdateContentItem: "requestUpdateContentItem",
      editContentItem: "editContentItem",
      reopenContentItem: "reopenContentItem",
      assignAuthor: "assignAuthor",
      assignLector: "assignLector",
      changeVisibility: "changeVisibility",
      loadTrackingOptions: "loadTrackingOptions",

      setContentItem: "setContentItem",
      resetContentItem: "resetContentItem",
      resetContributors: "resetContributors",
    }),
    async goBack() {
      const loc = this?.from?.name === "contentItems" ? this.from : { name: "contentItems" }
      await this.$router.push(loc)
    },
    async load() {
      await this.loadContentItems({
        id: this.id,
        for_update: this.updateRequestMode,
      })

      if (!this.model.text_class_id && this.model.author_fee && this.model.lector_fee) {
        this.model.text_class_id = TEXT_CLASS_ID_CUSTOM
      }
    },
    searchForContributors(args: any) {
      return async (query: string) => {
        await this.searchContributors({
          ...args,
          nameLike: query,
        })
      }
    },
    async handleSave() {
      await (this.$refs["form"] as typeof ElForm).validate(async (valid: boolean) => {
        if (valid) {
          try {
            let method

            switch (this.mode) {
              case MODE_REQUEST_NEW:
              default:
                if (this.bulk) {
                  this.model.subjects = this.subjectsInternal
                  method = this.requestNewContentItems
                } else {
                  method = this.requestNewContentItem
                }
                break
              case MODE_REQUEST_UPDATE:
                method = this.requestUpdateContentItem
                break
              case MODE_REQUEST_EDIT:
                method = this.editContentItem
                break
            }

            let result = await method({
              ...this.model,
              id: this.id,
            })

            if (this.bulk) {
              this.bulkResult = result
            } else {
              if (!Array.isArray(result)) {
                result = [result]
              }

              const assignments = result.map(async ({ job_id }) => {
                // eslint-disable-line camelcase
                if (job_id && this.isEditableAuthor && this.author_id) {
                  // eslint-disable-line camelcase
                  await this.assignAuthor({
                    job_id,
                    contributor_id: this.author_id || null,
                  })
                }
                if (job_id && this.isEditableLector && this.lector_id) {
                  // eslint-disable-line camelcase
                  await this.assignLector({
                    job_id,
                    contributor_id: this.lector_id || null,
                  })
                }
                return true
              })

              await Promise.all(assignments)

              this.$message(this.$t("form.saved"))
            }

            switch (this.mode) {
              case MODE_REQUEST_NEW:
                if (!this.bulk) {
                  await this.$router.push({
                    name: "contentItems",
                  })
                }
                break
              case MODE_REQUEST_UPDATE:
                await this.$router.push({
                  name: "contentItems",
                })
                break
              case MODE_REQUEST_EDIT:
              default:
                await this.load()
            }
          } catch (e: any) {
            console.log(e) // eslint-disable-line no-console
            switch (e?.response?.data?.hint) {
              case "duplicate_subject_in_asset":
                e.response.data.message_translated = this.$t("error.messages.duplicate_keyword_in_asset", {
                  subject: e?.response?.data?.details,
                })
            }
            this.requestError = e
          }
        } else {
          return false
        }
      })
    },
    async handleReassign() {
      if (this.isEditableAuthor && this.author_id) {
        await this.assignAuthor({
          job_id: this.model.job_id,
          contributor_id: this.author_id || null,
        })
      }
      if (this.isEditableLector && this.lector_id) {
        await this.assignLector({
          job_id: this.model.job_id,
          contributor_id: this.lector_id || null,
        })
      }
      this.$message(this.$t("form.saved"))
    },
    async handleReopen() {
      await this.reopenContentItem({ job_id: this.model.job_id })
      this.$message(this.$t("form.saved"))
      await this.goBack()
    },
    async handleUnpublish() {
      await this.changeVisibility({
        content_id: this.model.id,
        visibility: VISIBILITY_DRAFT,
      })
      this.$message(this.$t("form.saved"))
      await this.load()
    },
    async handlePublish() {
      await this.$refs["form"].validate(async (valid) => {
        if (valid) {
          await this.editContentItem({
            ...this.model,
            id: this.id,
          })
          await this.changeVisibility({
            content_id: this.id,
            visibility: VISIBILITY_PUBLISHED,
          })
          this.$message(this.$t("form.saved"))
          await this.load()
        } else {
          return false
        }
      })
    },
    async handleDelete() {
      let sure

      try {
        sure = await this.$confirm(this.$t("confirm.message"), this.$t("confirm.title"), {
          confirmButtonText: this.$t("confirm.confirm"),
          cancelButtonText: this.$t("confirm.cancel"),
          type: "warning",
        })
      } catch (e) {
        sure = e
      }

      if (sure) {
        await this.changeVisibility({
          content_id: this.model.id,
          visibility: VISIBILITY_DELETED,
        })
        this.$message(this.$t("form.saved"))
        await this.goBack()
      }
    },
    handleBtnGotoArticle(row) {
      if (row.source_url.indexOf(document.location.host) !== -1 || confirm(this.$t("message.you_are_leaving_nuke"))) {
        document.location.href = row.source_url
      }
    },
    handleBtnRedo() {
      this.bulkResult = []
      this.bulkDataRaw = "Thema\tFormat\tBriefing\n"
      this.subjectsInternal = ""
      this.model.subjects = []
    },
  },
  watch: {
    "model.author_id": function () {
      this.resetContributors()
    },
    "model.lector_id": function () {
      this.resetContributors()
    },
    "model.asset": {
      handler: async function (newVal, oldVal) {
        if (oldVal) {
          this.model.department_id = null
          this.model.format = null
        }
        if (newVal) {
          await this.loadDepartments({
            asset: newVal,
          })
          if (this.getDepartments.data.length === 1) {
            this.model.department_id = this.getDepartments.data[0].id
          }
          await this.loadAllContentFormatsForAsset({
            asset: newVal,
            sorting: [["name", "ascending"]],
          })
        }
      },
    },
    "model.format_id": function (newVal) {
      if (newVal) {
        const cf = this.contentFormatsList.filter(({ id }) => id === newVal)?.[0]
        if (cf?.tracking?.definition) {
          this.model.tracking.definition = cf.tracking.definition
        }
      }
    },
    bulkDataRaw: {
      handler: function (newVal) {
        this.subjectsInternal = newVal
      },
    },
  },
})
</script>

<style scoped>
.el-icon-warning {
  color: #e6a23c;
}
.el-icon-error {
  color: #f56c6c;
}
.el-icon-success {
  color: #67c23a;
}
.el-icon-success a {
  text-decoration: none;
  color: #606266;
  padding-left: 3px;
}
.el-icon-success a:hover {
  text-decoration: underline;
}
fieldset {
  border: 0;
}
fieldset legend {
  margin-bottom: 10px;
}
:deep(.el-form-item__label a) {
  text-decoration: none;
}
:deep(.el-form-item__label a:hover) {
  text-decoration: underline;
}
</style>
