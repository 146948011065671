<template>
  <el-config-provider :locale="locale">
    <header :class="{ visible: isInfobarVisible }">
      <update-check :everySeconds="600" @update-available="setInfobarHeight(40 + 2 * 8)"></update-check>
    </header>
    <el-container>
      <auth></auth>
      <el-header v-if="authorizedUser">
        <el-menu mode="horizontal" :ellipsis="true" :router="true" :default-active="menuActive">
          <el-menu-item>
            <router-link class="opacity-off" :to="{ name: 'briefings' }">
              <el-image v-if="isMobile" :src="logoSmallUrl" />
              <el-image v-else :src="logoUrl" />
            </router-link>
          </el-menu-item>
          <el-menu-item index="briefings" :route="{ name: 'briefings' }">
            {{ $t("briefing") }}
          </el-menu-item>
          <el-menu-item index="paymentsKeywords" :route="{ name: 'paymentsKeywords' }">{{
            $t("briefings_report")
          }}</el-menu-item>
          <el-menu-item v-if="canSeeWorkflows()" index="contentItems" :route="{ name: 'contentItems' }">{{
            $t("content_items")
          }}</el-menu-item>
          <el-menu-item index="users" v-if="canEditUsers()" :route="{ name: 'users' }">{{ $t("users") }}</el-menu-item>
          <el-menu-item index="ownProfile" :route="{ name: 'ownProfile' }">{{ $t("own_profile") }}</el-menu-item>
          <el-sub-menu index="5">
            <template v-slot:title>{{ $t("more") }}</template>
            <el-menu-item index="contentPieces" :route="{ name: 'contentPieces' }">{{ $t("articles") }}</el-menu-item>
            <el-menu-item v-if="canSeeRepublications()" index="republications" :route="{ name: 'republications' }">{{
              $t("republications")
            }}</el-menu-item>
            <el-menu-item index="5-2" :route="$route" @click="openExternal($t('help_url'))">
              {{ $t("help") }}
            </el-menu-item>
            <el-menu-item index="5-3" :route="$route" @click="openExternal($t('contact_url'))">
              {{ $t("contact") }}
            </el-menu-item>
            <el-menu-item index="5-4" :route="$route" @click="openExternal($t('report_bug_url'))">
              {{ $t("report_bug") }}
            </el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="6">
            <template v-slot:title
              ><el-icon><user-icon></user-icon></el-icon
            ></template>
            <el-menu-item index="profile" :route="{ name: 'profile' }">{{ $t("my_account") }}</el-menu-item>
            <el-menu-item index="logout" :route="{ name: 'logout' }">{{ $t("logout") }} </el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-header>
      <router-view :key="$route.path" />
    </el-container>
    <FeedbackDialog v-if="authorizedUser" />
  </el-config-provider>
</template>

<script lang="ts">
import { uuid } from "vue-uuid"
import Auth from "@/components/Auth.vue"
import UpdateCheck from "@/components/UpdateCheck.vue"
import { mapActions, mapState } from "pinia"
import { hasRole, ROLE_SEO, ROLE_MANAGER, ROLE_EDITOR } from "@/services/auth"
import { defineComponent } from "vue"
import useInfobarStore from "@/store/infobar"
import useAuthStore from "@/store/auth"
import useLangStore from "@/store/lang"
import { User as UserIcon } from "@element-plus/icons-vue"
import logoUrl from "@/assets/conributor-network-logo.svg"
import logoSmallUrl from "@/assets/conributor-network-logo-small.svg"
import FeedbackDialog from "@/components/FeedbackDialog.vue"
import { useWindowSize } from "@vueuse/core"

export default defineComponent({
  components: {
    FeedbackDialog,
    Auth,
    UpdateCheck,
    UserIcon,
  },
  i18nOptions: {
    namespaces: ["navigation"],
  },
  computed: {
    ...mapState(useAuthStore, {
      getToken: "getToken",
    }),
    ...mapState(useInfobarStore, {
      isInfobarVisible: "isVisible",
    }),
    ...mapState(useLangStore, ["locale"]),
    authorizedUser() {
      return !!this.getToken
    },
    logoSmallUrl() {
      return logoSmallUrl
    },
    logoUrl() {
      return logoUrl
    },
    menuActive() {
      switch (this.$route.name) {
        case "briefings":
          return "briefings"
        case "briefingsReport":
          return "briefingsReport"
        case "contentItems":
        case "contentItemRequestNew":
        case "contentItemRequestUpdate":
        case "contentItemEdit":
        case "contributors":
        case "departments":
        case "textClasses":
        case "contentFormats":
        case "trainingSessions":
        case "trainingSession":
        case "contributorWarnings":
        case "contributorWarningsModalEdit":
          return "contentItems"
        case "paymentsKeywords":
        case "paymentsInvoices":
        case "paymentsInvoice":
        case "paymentsPayouts":
          return "paymentsKeywords"
        case "contentPieces":
        case "contentPiece":
          return "contentPieces"
        case "users":
        case "user":
          return "users"
        case "contentFormatList":
        case "contentFormatEdit":
          return "contentFormatList"
        case "profile":
          return "profile"
        default:
          return "unknown"
      }
    },
    isMobile() {
      return useWindowSize().width.value < 1000
    },
  },
  methods: {
    ...mapActions(useInfobarStore, {
      setInfobarHeight: "setInfobarHeight",
    }),
    newContentPiece() {
      this.$router.push({ name: "contentPiece", params: { id: uuid.v1() } })
    },
    canSeeWorkflows() {
      return hasRole(ROLE_SEO)
    },
    canEditUsers() {
      return hasRole(ROLE_MANAGER)
    },
    canEditFormats() {
      return hasRole(ROLE_MANAGER)
    },
    canSeeRepublications() {
      return hasRole(ROLE_EDITOR)
    },
    openExternal(url: string) {
      if (url.startsWith("mailto:")) {
        window.location.href = url
      } else {
        window.open(url, "_blank")
      }
    },
  },
})
</script>
