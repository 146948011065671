import {
  getContentItems,
  updateContributor,
  loadContributors,
  searchContributors,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  getDepartments,
  createTextClass,
  updateTextClass,
  deleteTextClass,
  getTextClasses,
  getWorkflows,
  requestNewContentItem,
  requestUpdateContentItem,
  editContentItem,
  reopenContentItem,
  setDueDate,
  assignAuthor,
  assignLector,
  changeVisibility,
  createContentFormat,
  updateContentFormat,
  deleteContentFormat,
  getContentFormats,
  getCompanies,
  getTrackingOptions,
  requestNewContentItems,
} from "@/services/rest/workflows"

import clone from "lodash/cloneDeep"
import { defineStore } from "pinia"
import useBusyStore from "@/store/busy"
import type {
  Company,
  ContentFormat,
  ContentId,
  ContentItem,
  ContentItemId,
  Contributor,
  ContributorId,
  DataList,
  Department,
  DepartmentId,
  Job,
  JobId,
  Task,
  TaskId,
  TextClass,
  UserId,
  Workflow,
  WorkflowId,
} from "./types"
import type { SortingDirectives } from "@/services/rest"
import type { ContentVisibility } from "./types"
import { date } from "@/tools"

export const emptyUUID = "create"

export const emptyContentItem: ContentItem = {
  id: null,
  asset: null,
  department_id: null,
  subject: null,
  keyword: null,
  subjects: [],
  format_id: null,
  info: null,
  fastline: false,
  level: 0,
  advertorial: false,
  tracking: {},
  draft: false,
  text_class_id: null,
  author_fee: null,
  lector_fee: null,
  actions: {},
  author_id: null,
  lector_id: null,
  job_type: null,
  job_id: null,
  priority: "medium",
}

export const emptyTextClass: TextClass = {
  name: null,
  author_fee: 0,
  author_update_fee: 0,
  lector_fee: 0,
  lector_update_fee: 0,
}

export const emptyContentFormat: ContentFormat = {
  name: null,
  asset: null,
  url: null,
  tracking: {},
}

export const emptyDepartment: Department = {
  company_id: "",
  asset: "",
  cost_number: "",
  author_fee: 0,
  lector_fee: 0,
  author_fastline_bounty: 0,
  lector_fastline_bounty: 0,
  due_interval_fastline: "3 days",
  due_interval_standard: "7 days",
  fastline_withdraw_interval: "5 minutes",
  tax_rate: 0.0,
}

export const fieldsKeptForUpdateRequest = ["id", "asset", "department_id", "subject", "keyword", "format_id", "actions"]

export const jobTypes = ["create", "update"]

export const jobStatuses = [
  "open",
  "reserved",
  "in_progress",
  "ready_for_review",
  "review_in_progress",
  "overdue",
  "rejected",
  "completed",
  "unknown",
]

export const contentStatuses = ["draft", "published", "deleted"]

export const jobPriorities = ["low", "medium", "high"]

export const VISIBILITY_DRAFT = "draft"
export const VISIBILITY_PUBLISHED = "published"
export const VISIBILITY_DELETED = "deleted"
export const keywordVisibilities = [VISIBILITY_DRAFT, VISIBILITY_PUBLISHED]

const doable = (item: ContentItem, action: string) => item?.actions?.[action]?.doable === true

export type TaskExt = {
  date: Date
  action_outcome: string
  outcome_type: "started" | "delivered" | "resigned" | "canceled"
  outcome_contributor_user_id?: UserId
  outcome_contributor_user_name?: string
}
export type JobExt = {
  date: Date
}

interface State {
  contentItem: ContentItem
  contentItems: DataList<ContentItem>
  contributors: DataList<Contributor>
  contributorsNames: { [key: string]: string }
  departments: DataList<Department>
  textClasses: DataList<TextClass>
  contentFormats: DataList<ContentFormat>
  workflow: Workflow
  companies: DataList<Company>
  trackingOptions: Record<string, Record<string, { value: string; default: boolean }[]>>
}

export function isJob(item: any): item is Job & JobExt {
  return item.tasks && item.date
}
export function isTaskAndTaskExt(item: any): item is Task & TaskExt {
  return item.start_date && item.date
}

function* flattenWorkflow(workflow: Workflow): Generator<(Task & TaskExt) | (Job & JobExt), void> {
  for (const job of workflow.jobs ?? []) {
    yield { ...job, date: date(job.create_date) as Date }
    for (const task of job.tasks) {
      yield {
        ...task,
        date: date(task.start_date) as Date,
        outcome_type: "started",
        action_outcome: "started",
        outcome_contributor_user_id: task.outcome?.start?.user_id,
      }

      if (task.cancel_date) {
        yield {
          ...task,
          date: date(task.cancel_date) as Date,
          outcome_type: "canceled",
          action_outcome: task.outcome.cancel?.outcome || "",
          outcome_contributor_user_id: task.outcome.cancel?.user_id,
        }
      }
      if (task.resign_date) {
        yield {
          ...task,
          date: date(task.resign_date) as Date,
          outcome_type: "resigned",
          action_outcome: task.outcome.resign?.outcome || "",
          // outcome_contributor_user_id: task.outcome.resign?.user_id,
        }
      }
      if (task.deliver_date) {
        yield {
          ...task,
          date: date(task.deliver_date) as Date,
          outcome_type: "delivered",
          action_outcome: task.outcome.deliver?.outcome || "",
          outcome_contributor_user_id: task.outcome.deliver?.user_id,
        }
      }
    }
  }
}
export default defineStore("workflows", {
  state: (): State => ({
    contentItem: clone(emptyContentItem),
    contentItems: { data: [], total: 0 },
    contributors: { data: [], total: 0 },
    contributorsNames: {},
    departments: { data: [], total: 0 },
    textClasses: { data: [], total: 0 },
    contentFormats: { data: [], total: 0 },
    workflow: {} as Workflow,
    companies: { data: [], total: 0 },
    trackingOptions: {},
  }),
  getters: {
    /**
     * @deprecated
     */
    busy: () => useBusyStore().busy,
    getContentItem: (state) => state.contentItem,
    getContentItems: (state) => state.contentItems,
    getContributors: (state) => state.contributors,
    getContributorsNames: (state) => (id: string) => state.contributorsNames[id],
    getDepartments: (state) => state.departments,
    getTextClasses: (state) => state.textClasses,
    getContentFormats: (state) => state.contentFormats,
    getContentTypes: () => ["article", "quiz", "gallery"],
    getWorkflow: (state) => state.workflow,
    getFlattenedWorkflowTasks: function (state) {
      return Array.from(flattenWorkflow(state.workflow)).map((item) =>
        isTaskAndTaskExt(item)
          ? {
              ...item,
              outcome_contributor_user_name: state.contributorsNames[item.outcome_contributor_user_id ?? ""] ?? "",
            }
          : item,
      )
    },
    canRequestUpdateContentItem: (state) => doable(state.contentItem, "request_update_content_item"),
    canEditCurrentContentItemRequest: (state) => doable(state.contentItem, "edit_current_content_item_request"),
    canReopenContentItem: (state) => doable(state.contentItem, "reopen_content_item"),
    canReassignContentItemAuthor: (state) => doable(state.contentItem, "reassign_author"),
    canReassignContentItemLector: (state) => doable(state.contentItem, "reassign_lector"),
    canSetVisibilityDraft: (state) => doable(state.contentItem, "set_visibility_draft"),
    canSetVisibilityPublished: (state) => doable(state.contentItem, "set_visibility_published"),
    canSetVisibilityDeleted: (state) => doable(state.contentItem, "set_visibility_deleted"),
    getCompanies: (state) => state.companies,
    getTrackingOptions: (state) => state.trackingOptions,
  },
  actions: {
    async loadContentItems({
      for_update: forUpdate,
      ...args
    }: { for_update?: boolean; id?: ContentItemId } & { [_: string]: any }) {
      const { setBusy } = useBusyStore()

      // eslint-disable-line camelcase
      const setIdle = setBusy("loading_content_items")

      try {
        const result = await getContentItems(args)
        if (args?.id) {
          if (forUpdate) {
            result.data[0] = Object.keys(result.data[0]).reduce((all, key: string) => {
              return {
                ...all,
                [key]: fieldsKeptForUpdateRequest.includes(key)
                  ? result.data[0][key as keyof ContentItem]
                  : emptyContentItem[key as keyof ContentItem],
              }
            }, {}) as ContentItem
          }
          this.setContentItem(result.data[0])
        } else {
          this.setContentItems(result)
        }
      } finally {
        setIdle()
      }
    },
    async loadContributors(args = {}) {
      const { setBusy } = useBusyStore()
      const setIdle = setBusy("loading_contributors")

      try {
        const result = await loadContributors(args)
        this.setContributors(result)
      } finally {
        setIdle()
      }
    },
    async searchContributors(args = {}) {
      const { setBusy } = useBusyStore()
      const setIdle = setBusy("searching_contributors")

      try {
        const result = await searchContributors<Contributor>(args)
        this.setContributors(result)
      } finally {
        setIdle()
      }
    },
    async loadDepartments(args: any): Promise<void> {
      const { setBusy } = useBusyStore()
      const setIdle = setBusy("loading_departments")

      try {
        const result = await getDepartments(args)
        this.setDepartments(result)
      } finally {
        setIdle()
      }
    },
    async loadTextClasses(
      args: { sort?: SortingDirectives; forContributor?: boolean } = {
        sort: [
          ["author_fee", "ascending"],
          ["lector_fee", "ascending"],
        ],
      },
    ) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("loading_text_classes")

      const { forContributor, ...restOfargs } = args
      try {
        const result = await getTextClasses({ ...restOfargs, ...(forContributor ? { select: ["id", "name"] } : {}) })
        this.setTextClasses(result)
      } finally {
        setIdle()
      }
    },
    async loadContentFormats(args = {}) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("loading_content_formats")

      try {
        const result = await getContentFormats(args)
        this.setContentFormats(result)
      } finally {
        setIdle()
      }
    },
    async loadWorkflows(args: { id?: WorkflowId } = {}) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("loading_workflows")

      try {
        const result = await getWorkflows(args)
        if (args?.id) {
          this.setWorkflow(result[0])
        }
      } finally {
        setIdle()
      }
    },
    async requestNewContentItem(model: ContentItem) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("requesting_new_content_item")

      try {
        return await requestNewContentItem(model)
      } finally {
        setIdle()
      }
    },
    async requestNewContentItems(model: ContentItem) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("requesting_new_content_items")

      try {
        return await requestNewContentItems(model)
      } finally {
        setIdle()
      }
    },
    async requestUpdateContentItem(model: ContentItem) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("requesting_update_content_item")

      try {
        return await requestUpdateContentItem(model)
      } finally {
        setIdle()
      }
    },
    async editContentItem(model: ContentItem) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("editing_content_item")

      try {
        return await editContentItem(model)
      } finally {
        setIdle()
      }
    },
    async reopenContentItem({ job_id }: { job_id: JobId }) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("reopening_content_item")

      try {
        return await reopenContentItem(job_id)
      } finally {
        setIdle()
      }
    },
    async setDueDate({ task_id, due_date }: { task_id: TaskId; due_date: Date }) {
      const { setBusy } = useBusyStore()
      const setIdle = setBusy("setting_due_date")

      try {
        return await setDueDate(task_id, due_date)
      } finally {
        setIdle()
      }
    },
    async assignAuthor({ job_id, contributor_id }: { job_id: JobId; contributor_id: ContributorId }) {
      const { setBusy } = useBusyStore()
      const setIdle = setBusy("assigning_author")

      try {
        return await assignAuthor(job_id, contributor_id)
      } finally {
        setIdle()
      }
    },
    async changeVisibility({ content_id, visibility }: { content_id: ContentId; visibility: ContentVisibility }) {
      const { setBusy } = useBusyStore()
      const setIdle = setBusy("changing_visibility")

      try {
        return await changeVisibility(content_id, visibility)
      } finally {
        setIdle()
      }
    },
    async assignLector({ job_id, contributor_id }: { job_id: JobId; contributor_id: ContributorId }) {
      const { setBusy } = useBusyStore()
      const setIdle = setBusy("assigning_lector")

      try {
        return await assignLector(job_id, contributor_id)
      } finally {
        setIdle()
      }
    },
    async updateContributor(model: Contributor) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("updating_contributor")

      try {
        return await updateContributor(model)
      } finally {
        setIdle()
      }
    },
    async createDepartment(model: Department) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("creating_department")

      try {
        return await createDepartment(model)
      } finally {
        setIdle()
      }
    },
    async updateDepartment(model: Department) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("updating_department")

      try {
        return await updateDepartment(model)
      } finally {
        setIdle()
      }
    },
    async deleteDepartment({ id }: { id: DepartmentId }) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("deleting_department")
      try {
        return await deleteDepartment(id)
      } finally {
        setIdle()
      }
    },
    async createTextClass(model: TextClass) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("creating_text_class")
      try {
        return await createTextClass(model)
      } finally {
        setIdle()
      }
    },
    async updateTextClass(model: TextClass) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("updating_text_class")
      try {
        return await updateTextClass(model)
      } finally {
        setIdle()
      }
    },
    async deleteTextClass({ id }: { id: TextClass }) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("deleting_text_class")
      try {
        return await deleteTextClass({ id })
      } finally {
        setIdle()
      }
    },
    async createContentFormat(model: ContentFormat) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("creating_content_format")
      try {
        return await createContentFormat(model)
      } finally {
        setIdle()
      }
    },
    async updateContentFormat(model: ContentFormat) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("updating_content_format")
      try {
        return await updateContentFormat(model)
      } finally {
        setIdle()
      }
    },
    async deleteContentFormat(args: any) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("deleting_content_format")
      try {
        return await deleteContentFormat(args)
      } finally {
        setIdle()
      }
    },

    async loadCompanies(args = {}) {
      const { setBusy } = useBusyStore()

      const setIdle = setBusy("loading_companies")
      try {
        const result = await getCompanies(args)
        this.setCompanies(result)
      } finally {
        setIdle()
      }
    },
    async loadTrackingOptions() {
      const result = await getTrackingOptions()
      this.setTrackingOptions(result)
    },
    // mutator actions
    // @TODO: inline simple mutators

    setContentItem(data: ContentItem) {
      this.contentItem = data
    },
    resetContentItem() {
      this.contentItem = clone(emptyContentItem)
    },
    setContentItems(data: DataList<ContentItem>) {
      this.contentItems = data
    },
    setContributors(data: DataList<Contributor>) {
      this.contributorsNames = {
        ...this.contributorsNames,
        ...Object.fromEntries(data.data.map((v) => [v.id, v.name || ""])),
        ...Object.fromEntries(data.data.map((v) => [v.user_id, v.name || ""])),
      }
      this.contributors = data
    },
    resetContributors() {
      this.contributors = { data: [], total: 0 }
    },
    setDepartments(data: DataList<Department>) {
      this.departments = data
    },
    setTextClasses(data: DataList<TextClass>) {
      this.textClasses = data
    },
    setContentFormats(data: DataList<ContentFormat>) {
      this.contentFormats = data
    },
    setWorkflow(data: Workflow) {
      this.workflow = data
    },
    setCompanies(data: DataList<Company>) {
      this.companies = data
    },
    setTrackingOptions(data: { dimension: string; value: string; default: boolean; content_type: string }[]) {
      const trackingOptions = {} as Record<string, Record<string, { value: string; default: boolean }[]>>

      data.forEach((cur) => {
        trackingOptions[cur.content_type] = trackingOptions?.[cur.content_type] ?? {}
        trackingOptions[cur.content_type][cur.dimension] = trackingOptions?.[cur.content_type]?.[cur.dimension] ?? []
        trackingOptions[cur.content_type][cur.dimension].push({ value: cur.value, default: cur.default || false })
      })

      this.trackingOptions = trackingOptions
    },
  },
})
