<template>
  <span v-if="$developer" style="margin-left: 10px; margin-right: 10px">
    <el-button @click="fillData">
      <el-icon><edit-icon></edit-icon></el-icon>
    </el-button>
  </span>
</template>

<script lang="ts">
import { Edit as EditIcon } from "@element-plus/icons-vue"

export default {
  name: "FixturesContentItem",
  components: {
    EditIcon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fillData() {
      this.$emit(
        "update:modelValue",
        Object.fromEntries(
          Object.entries({
            ...this.modelValue,
            ...{
              info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
          }),
        ),
      )
    },
  },
}
</script>

<style scoped></style>
