import enUI from "element-plus/es/locale/lang/en"
import deUI from "element-plus/es/locale/lang/de"

import enNuke from "@/locale/nuke_en.json"
import deNuke from "@/locale/nuke_de.json"

import editorjsEn from "@/locale/editorjs_en.json"
import editorjsDe from "@/locale/editorjs_de.json"
// import type { LocaleMessageObject } from "vue-i18n";

export const en = {
  ...enNuke,
  el: enUI.el,
  editorjs: editorjsEn,
  name: "English",
}
export const de = {
  ...deNuke,
  el: deUI.el,
  edtorjs: editorjsDe,
  name: "Deutsch",
}
export default { en, de }
