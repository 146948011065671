import { hasRole } from "@/services/auth"
import type { Router } from "vue-router"

export const apply = (router: Router) => {
  router.beforeEach((to) => {
    if (!to.meta.role) {
      return
    }
    if (hasRole((to?.meta?.role || "") as string)) {
      return
    }

    return {
      name: "unauthorized",
    }
  })
}
