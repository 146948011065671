import { defineStore } from "pinia"
import { computed, ref } from "vue"
import type { Ref } from "vue"
import type { RouteLocationNormalized, Router } from "vue-router"

let router: Router

export function init(_router: Router) {
  router = _router
}

export default defineStore("route", () => {
  const localFrom: Ref<RouteLocationNormalized | undefined> = ref()
  const localTo: Ref<RouteLocationNormalized | undefined> = ref()

  const name = computed(() => localTo.value?.name)
  const query = computed(() => localTo.value?.query)
  const path = computed(() => localTo.value?.path || "")
  const meta = computed(() => localTo.value?.meta)
  const params = computed(() => localTo.value?.params)
  const from = computed(() => localFrom.value)

  router.afterEach((_to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
    if (_from.fullPath !== _to.fullPath) {
      localFrom.value = _from
      localTo.value = _to
    }
  })

  return { name, query, path, meta, params, from }
})
