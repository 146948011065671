import { defineStore } from "pinia"
import { computed, ref, type Ref } from "vue"
import type { Busy } from "./types"

export default defineStore("busy", () => {
  const IDLE: Busy = { with: null, since: null }

  const busyList: Ref<Busy[]> = ref([])

  const busy = computed(() => {
    return busyList.value.length ? busyList.value[busyList.value.length - 1] : IDLE
  })

  function setBusy(action: string) {
    const since = new Date().getTime()
    busyList.value = [...busyList.value, { with: action, since }]

    return () => {
      busyList.value = busyList.value.filter((item) => !(item.with === action && item.since === since))
    }
  }
  function setIdle() {
    console.warn("deprecated setIdle()")
  }

  return { busy, busyList, setBusy, setIdle }
})
