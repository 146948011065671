import axios from "axios"
import type { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios"
export let client: AxiosInstance

interface RestSettings {
  baseURL: string
  httpAuth: string
}
let tokenProvider: () => string

export function init(settings: RestSettings, tokenProviderFn: () => string) {
  tokenProvider = tokenProviderFn
  client = axios.create({
    baseURL: settings.baseURL,
  })
}

export enum ReturnRepresentation {
  MINIMAL = "minimal",
  HEADERS_ONLY = "headers-only",
  FULL = "representation",
}

type Query = Record<string, any>
type Headers = Record<string, string>

export const buildArgs = (args: Record<string, any>, prefix?: string) =>
  Object.fromEntries(
    Object.entries(args)
      .map(([k, v]) => (prefix ? [`${prefix}.${k}`, v] : [k, v]))
      .map(([k, v]) => {
        if (k.match(/.*Like$/)) {
          return [k.replace(/Like$/, ""), `ilike.*${v}*`]
        } else if (k.match(/.*Search$/)) {
          return [k.replace(/Search$/, ""), `plfts(german).${v}`]
        } else if (k.match(/.*Contains$/)) {
          return !Array.isArray(v) || (Array.isArray(v) && v.filter((i) => i !== undefined).length)
            ? [k.replace(/Contains$/, ""), `cs.{${Array.isArray(v) ? v.join(",") : v}}`]
            : undefined
        } else if (["or", "and"].includes(k)) {
          return v.length
            ? [
                k,
                "(" +
                  v.map((v) =>
                    Object.entries(buildArgs(v))
                      .map(([k, v]) => `${k}.${v}`)
                      .join(","),
                  ) +
                  ")",
              ]
            : undefined
        } else if (["offset", "limit", "order"].includes(k)) {
          return [k, v]
        } else if (k === "select" || k === "columns") {
          return [k, Array.isArray(v) ? v.join(",") : v]
        } else if (k.match(/.*>=$/)) {
          return [k.replace(/>=$/, ""), `gte.${v}`]
        } else if (k.match(/.*=<$/)) {
          return [k.replace(/=<$/, ""), `lte.${v}`]
        } else if (k.match(/.*<$/)) {
          return [k.replace(/<$/, ""), `lt.${v}`]
        } else if (k.match(/.*Range$/)) {
          return Array.isArray(v) && v.length === 2
            ? Object.entries(
                buildArgs({
                  and: [{ [`${k.replace(/Range$/, "")}>=`]: v[0] }, { [`${k.replace(/Range$/, "")}<`]: v[1] }],
                }),
              )[0]
            : undefined
        } else if (Array.isArray(v)) {
          return v.filter((i) => i !== undefined).length
            ? [k, `in.(${v.filter((i) => i !== undefined).join(",")})`]
            : undefined
        } else if (v === undefined) {
          return [k, v]
        } else {
          return [k, typeof v === "boolean" || v === null ? `is.${v}` : `eq.${v}`]
        }
      })
      .filter((i) => i)
      .map(([k, v]) => {
        return k.match(/.*Not$/) ? [k.replace(/Not$/, ""), `not.${v}`] : [k, v]
      }),
  )

export const tokenHeader = (token: string): Headers => (token ? { "X-Token": `${token}` } : {})

export const contentTypeHeader = (contentType?: string): Headers => (contentType ? { "Content-Type": contentType } : {})

export const singularHeader = (bool = true): Headers => (bool ? { Accept: "application/vnd.pgrst.object+json" } : {})

export const countHeader = (bool = true): Headers => (bool ? { Prefer: "count=exact" } : {})

export const returnHeader = (val: ReturnRepresentation | "minimal" | "headers-only" | "representation"): Headers =>
  val ? { Prefer: `return=${val}` } : {}

export const singleObjectHeader = (bool = true): Headers => (bool ? { Prefer: "params=single-object" } : {})

export const acceptProfileHeader = (profile: string): Headers => ({
  "Accept-Profile": profile,
})

export const contentProfileHeader = (profile: string): Headers => ({
  "Content-Profile": profile,
})

const requestFactory = (query: Query = {}, headers: Headers = {}): AxiosRequestConfig => {
  const token = tokenProvider() ?? ""

  return {
    params: query,
    headers: {
      ...contentTypeHeader("application/json"),
      ...tokenHeader(token),
      ...headers,
    },
  }
}
export const ASC = "ascending"
export const DESC = "descending"
export const NULLS_FIRST = "nullsfirst"
export const NULLS_LAST = "nullslast"

export type ColumnName = string
export type SortDirection = typeof ASC | typeof DESC

type SortingNullsInclude = typeof NULLS_FIRST | typeof NULLS_LAST
type SortingDirective =
  | [ColumnName, SortDirection, SortingNullsInclude]
  | [ColumnName, SortDirection]
  | [ColumnName, SortingNullsInclude]

export type SortingDirectives = SortingDirective[]

const isSortDirection = (x: string): x is SortDirection => x == "ascending" || x == "descending"
/**
 * [[col1,dir1], [col2,dir2,nulls2], [col3,nulls3]] => {order:'col1.dir1,col2.dir2.nulls2,col3.nulls3'}
 * @param sorting
 * @returns {{order: String}}
 */
export const buildSorting = (sorting?: SortingDirectives): { order?: string } =>
  sorting && sorting?.length > 0
    ? {
        order: sorting
          .map(([col, dir, nulls]) => [
            col,
            ...(isSortDirection(dir) ? [{ ascending: "asc", descending: "desc" }[dir]] : []),
            ...(nulls ? [nulls] : []),
          ])
          .map((parts) => parts.join("."))
          .join(","),
      }
    : {}

export type SelectColumnList = { select: ColumnName[] }
export type UpdateColumnList = { columns: ColumnName[] }

export type Paging = {
  page: number
  perPage: number
}

export const rangeHeader = (paging: Paging) => {
  const offset = (paging.page - 1) * paging.perPage
  const limit = offset + paging.perPage
  return { "Range-Unit": "items", Range: `${offset}-${limit}` }
}

export const rangeParams = (paging?: Paging) => {
  if (!paging) return {}
  return {
    offset: (paging.page - 1) * paging.perPage,
    limit: paging.perPage,
  }
}

function maybeIncludePaginationData<R>(
  resp: AxiosResponse<any>,
  query: Record<string, any>,
  headers: Record<string, string>,
): R {
  const [k, v] = Object.entries(countHeader()).shift() ?? ["", ""]

  return headers?.[k] === v
    ? {
        total: parseInt(resp.headers?.["content-range"].split("/").pop() || 0),
        data: resp?.data || [],
      }
    : resp?.data || null
}

export async function get<R>(path: string, query: Query = {}, headers: Headers = {}) {
  const resp = await client.get(path, requestFactory(query, headers))
  return maybeIncludePaginationData<R>(resp, query, headers)
}

export async function post<R = void>(path: string, data = {}, query: Query = {}, headers: Headers = {}) {
  const resp = await client.post(path, data, requestFactory(query, headers))
  return maybeIncludePaginationData<R>(resp, query, headers)
}

export async function patch<R>(path: string, data = {}, query: Query = {}, headers: Headers = {}): Promise<R> {
  const resp = await client.patch(path, data, requestFactory(query, headers))
  return resp?.data || null
}

export async function put<R>(path: string, data = {}, query: Query = {}, headers: Headers = {}): Promise<R> {
  const resp = await client.put(path, data, requestFactory(query, headers))
  return resp?.data || null
}

export async function del<R>(path: string, query: Query = {}, headers: Headers = {}): Promise<R> {
  const resp = await client.delete(path, requestFactory(query, headers))
  return resp?.data || null
}
