import { getName, getUUID, login } from "@/services/rest/auth"
import { defineStore } from "pinia"
import type { Profile } from "@/store/types"

interface State {
  token: string | null
  goto: any | null
  uuid: string | null
  name: string | null
}
let loginSuccess: Function
let loginFailed: Function

export default defineStore("auth", {
  state: (): State => ({
    token: null,
    goto: null,
    uuid: null,
    name: null,
  }),
  getters: {
    getToken: (state) => state.token,
    getProfile: (state): Profile => (state.token ? JSON.parse(atob(state.token.split(".")[1])) : {}),
    getGoTo: (state) => state.goto,
    getUUID: (state) => state.uuid,
    getName: (state) => state.name,
  },
  actions: {
    async loadUUID() {
      this.uuid = await getUUID()
    },
    async loadName() {
      this.name = await getName()
    },
    loginRequest() {
      this.token = ""
      return new Promise((resolve, reject) => {
        loginSuccess = resolve
        loginFailed = reject
      })
    },
    async login({ email, pass }: { email: string; pass: string }) {
      try {
        const result = await login(email, pass)
        this.token = result.token
        loginSuccess()
        return true
      } catch (e) {
        loginFailed()
        return false
      }
    },
    logout() {
      this.token = null
      this.uuid = null
    },
  },
})
