import { post, singularHeader } from "./index"
import type { Email } from "@/store/types"

export function login(email: Email, pass: string) {
  return post<{ token: string }>("/rpc/login", { email, pass }, {}, singularHeader())
}

export function refresh() {
  return post<{ token: string }>("/rpc/refresh_token", {}, {}, singularHeader())
}

export function getUUID() {
  return post<string>("/rpc/current_user_id", {}, {}, singularHeader())
}

export function getName() {
  return post<string>("/rpc/current_user_name", {}, {}, singularHeader())
}

export function updatePush(push: any) {
  return post("/rpc/update_push", { push })
}
