<template>
  <span>{{ currencyFormatted }}</span>
</template>

<script lang="ts">
import { formatCurrency } from "@/tools"
import { defineComponent } from "vue"

export default defineComponent({
  name: "CurrencyFormatted",
  i18nOptions: {
    namespaces: ["common"],
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currencyFormatted() {
      return formatCurrency(this.value)
    },
  },
})
</script>
