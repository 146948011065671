import { buildArgs, countHeader, get, post, singularHeader, buildSorting, type SortingDirectives } from "./index"
import type { ContentFormat, ContentFormatSettings, DataList } from "@/store/types"

export function getList(filters = {}, sorting: SortingDirectives = [], paging = {}) {
  return get<DataList<ContentFormat>>(
    "/content_formats_list",
    buildArgs({
      ...filters,
      ...buildSorting(sorting),
      ...paging,
    }),
    countHeader(),
  )
}

export function getSettings(asset: string, name: string) {
  return post<ContentFormatSettings>(
    "/rpc/get_content_format_settings",
    {
      asset,
      name,
    },
    {},
    singularHeader(),
  )
}
