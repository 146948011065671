<template>
  <div class="infobar-section" v-if="isUpdateAvailable">
    {{ $t("info_text") }}
    <el-button @click="handleReload" type="success">{{ $t("reload") }}</el-button>
  </div>
</template>

<script lang="ts">
import { v3 as hash } from "murmurhash"
import { defineComponent } from "vue"

export default defineComponent({
  name: "UpdateCheck",
  i18nOptions: {
    namespaces: ["update_check"],
  },
  props: {
    everySeconds: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.start()
  },
  data: () => ({
    pagehash: null as number | null,
    timer: null as number | null,
    isUpdateAvailable: false,
    sentinelUrl: "", // Because this is a single-page-app we can simply poll the current page.
  }),
  methods: {
    start() {
      if (this.timer) {
        return
      }
      const check = async () => {
        try {
          await this.check()
        } catch (e: any) {
          /* ignore */
        }
      }
      this.timer = window.setInterval(check, this.everySeconds * 1000)
      check()
    },
    stop() {
      if (!this.timer) {
        return
      }
      window.clearInterval(this.timer)
      this.timer = null
    },
    async check() {
      const resp = await fetch(this.sentinelUrl)
      const pagehash = hash(await resp.text())
      this.isUpdateAvailable = this.pagehash !== null && pagehash !== this.pagehash
      this.pagehash = pagehash
    },
    handleReload() {
      window.location.reload()
    },
  },
  watch: {
    isUpdateAvailable(isAvailable, wasAvailable) {
      if (isAvailable && !wasAvailable) {
        this.stop()
        this.$emit("update-available")
      }
    },
  },
})
</script>

<style scoped>
.infobar-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ced7e7;
}

button {
  margin-left: 1em;
}
</style>
