export default {
  api: {
    baseURL: import.meta.env.VITE_APP_API_BASE_URL,
    httpAuth: import.meta.env.VITE_APP_API_HTTP_AUTH,
  },
  webpush: {
    publicKey: import.meta.env.VITE_APP_WEBPUSH_PUBLIC_KEY,
  },
  cgi: {
    uploadURL: import.meta.env.VITE_APP_CGI_UPLOAD_URL,
    fetchURL: import.meta.env.VITE_APP_CGI_FETCH_URL,
    glisURL: import.meta.env.VITE_APP_CGI_GLIS_URL,
    BIExportURL: import.meta.env.VITE_APP_CGI_BI_EXPORT_URL,
    serpURL: import.meta.env.VITE_APP_CGI_SERP_URL,
    uploadLimit: import.meta.env.VITE_APP_CGI_UPLOAD_LIMIT,
  },
  kwiss: {
    baseURL: import.meta.env.VITE_APP_KWISS_BASE_URL,
  },
  features: {
    changelog: false,
  },
}
