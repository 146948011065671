import { refresh } from "@/services/rest/auth"
import { isTokenEmptyOrExpired } from "@/tools"
import useAuthStore from "@/store/auth"
import { watch } from "vue"

export const sync = () => {
  const authStore = useAuthStore()
  const token = localStorage.getItem("token")

  const scheduleRefresh = () => {
    const profile = authStore.getProfile

    const now = new Date().getTime()
    const exp = profile.exp * 1000
    const gap = 5 * 60 * 1000

    setTimeout(async () => {
      const result = await refresh()
      authStore.token = result.token
    }, exp - gap - now)
  }

  watch(
    () => authStore.token,
    (newValue) => {
      if (newValue) {
        localStorage.setItem("token", newValue)
        scheduleRefresh()
      } else {
        localStorage.removeItem("token")
      }
    },
  )

  if (!isTokenEmptyOrExpired(token)) {
    authStore.token = token
  }
}
