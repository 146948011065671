import { getList, getSettings } from "@/services/rest/contentFormats"
import type { ContentFormat, ContentFormatId, ContentFormatSettings, DataList } from "@/store/types"
import { defineStore } from "pinia"
import type { SortingDirectives } from "@/services/rest"

interface State {
  contentFormatsList: DataList<ContentFormat>
  contentFormat: ContentFormat | null
  contentFormatSettings: ContentFormatSettings | null
}

export default defineStore("contentFormats", {
  state: (): State => ({
    contentFormatsList: { data: [], total: 0 },
    contentFormat: null,
    contentFormatSettings: null,
  }),
  getters: {
    getContentFormatsList: (state) =>
      state.contentFormatsList.data.map(({ content_type, ...rest }) =>
        !content_type ? { ...rest, content_type: "article" } : { ...rest, content_type },
      ),
    getContentFormatById: (state) => (searchId: ContentFormatId) =>
      state.contentFormatsList.data.find?.(({ id }) => id === searchId),
    getContentFormatSettings: (state) => state.contentFormatSettings,
  },
  actions: {
    async loadAllContentFormatsForAsset({
      sorting = [],
      paging = {},
      ...args
    }: { sorting: SortingDirectives; paging?: any } & any) {
      const result = await getList(args, sorting, paging)
      this.setContentFormatsList({ result })
    },
    async loadContentFormatSettings({ asset, name }: { asset: string; name: string }) {
      const result = await getSettings(asset, name)
      this.setContentFormatSettings(result)
    },
    // mutations
    setContentFormatsList({ result }: { result: DataList<ContentFormat> }) {
      this.contentFormatsList = result
    },
    setContentFormatSettings(data: ContentFormatSettings) {
      this.contentFormatSettings = data
    },
  },
})
