import { createRouter, createWebHashHistory } from "vue-router"
import type { RouteRecordRaw } from "vue-router"
import { ROLE_CONTRIBUTOR, ROLE_LECTOR, ROLE_SEO, ROLE_MANAGER, ROLE_EDITOR } from "@/services/auth"
import { MODE_REQUEST_NEW, MODE_REQUEST_UPDATE, MODE_REQUEST_EDIT } from "@/views/Workflows/ContentItem.vue"

const routes: RouteRecordRaw[] = [
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("@/views/Unauthorized.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/Logout.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/request-password-recovery",
    name: "requestPasswordRecovery",
    component: () => import("@/views/RequestPasswordRecovery.vue"),
  },
  {
    path: "/change-password/:token",
    name: "changePassword",
    component: () => import("@/views/ChangePassword.vue"),
  },
  {
    path: "/content-pieces",
    name: "contentPieces",
    component: () => import("@/views/ContentPieces.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/",
    name: "briefings",
    component: () => import("@/views/Briefing.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/content-items",
    name: "contentItems",
    component: () => import("@/views/Workflows/ContentItems.vue"),
    meta: {
      protected: true,
      role: ROLE_SEO,
    },
  },
  {
    path: "/content-item/request-new",
    name: "contentItemRequestNew",
    component: () => import("@/views/Workflows/ContentItem.vue"),
    meta: {
      protected: true,
      role: ROLE_SEO,
    },
    props: (route) => ({
      mode: MODE_REQUEST_NEW,
      asset: route.query.asset,
    }),
  },
  {
    path: "/content-item/request-update/:id",
    name: "contentItemRequestUpdate",
    component: () => import("@/views/Workflows/ContentItem.vue"),
    meta: {
      protected: true,
      role: ROLE_SEO,
    },
    props: (route) => ({
      mode: MODE_REQUEST_UPDATE,
      id: route.params.id,
      asset: route.query.asset,
    }),
  },
  {
    path: "/content-item/:id",
    name: "contentItemEdit",
    component: () => import("@/views/Workflows/ContentItem.vue"),
    meta: {
      protected: true,
      role: ROLE_SEO,
    },
    props: (route) => ({
      mode: MODE_REQUEST_EDIT,
      id: route.params.id,
    }),
  },
  {
    path: "/content-formats",
    name: "contentFormats",
    component: () => import("@/views/Workflows/ContentFormats.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/contributors",
    name: "contributors",
    component: () => import("@/views/Workflows/Contributors.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/departments",
    name: "departments",
    component: () => import("@/views/Workflows/Departments.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/text-classes",
    name: "textClasses",
    component: () => import("@/views/Workflows/TextClasses.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/training-sessions",
    name: "trainingSessions",
    component: () => import("@/views/Workflows/TrainingSessions.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/training-session/:id",
    name: "trainingSession",
    component: () => import("@/views/Workflows/TrainingSession.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/contributor-warnings/",
    name: "contributorWarnings",
    component: () => import("@/views/Workflows/ContributorWarnings.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
    children: [
      {
        component: () => import("@/components/workflows/WarningEditForm.vue"),
        name: "contributorWarningsModalEdit",
        path: "edit/:id",
      },
    ],
  },
  // {
  //   component: () => false,
  //   name: "contributorWarningsModalEdit",
  //   path: "/contributor-warnings/edit/:id",
  // },
  {
    path: "/payments-keywords",
    name: "paymentsKeywords",
    component: () => import("@/views/Payments/Keywords.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/payments-invoices",
    name: "paymentsInvoices",
    component: () => import("@/views/Payments/Invoices.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/payments-invoice/:id",
    name: "paymentsInvoice",
    component: () => import("@/views/Payments/Invoice.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/payments-payouts",
    name: "paymentsPayouts",
    component: () => import("@/views/Payments/Payouts.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/content-piece/:id",
    name: "contentPiece",
    component: () => import("@/views/ContentPiece.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/content-piece-cms/:id",
    name: "contentPieceCms",
    component: () => import("@/views/ContentPieceCms.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/content-piece/:id/changes",
    name: "contentChangelogView",
    component: () => import("@/views/ContentChangelogView.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/content-piece-preview/",
    name: "contentPiecePreview",
    component: () => import("@/views/ContentPiecePreview.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/Users/UsersList.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/user/me",
    component: () => import("@/views/Users/MeIndex.vue"),
    children: [
      {
        path: "",
        name: "profile",
        component: () => import("@/views/Users/MeEdit.vue"),
        meta: {
          protected: true,
          role: ROLE_CONTRIBUTOR,
        },
      },
      {
        path: "billing",
        name: "billing",
        component: () => import("@/views/Users/Billing.vue"),
        meta: {
          protected: true,
          role: ROLE_CONTRIBUTOR,
        },
      },
      {
        path: "authors",
        name: "userAuthors",
        component: () => import("@/views/Users/Authors.vue"),
        meta: {
          protected: true,
          role: ROLE_CONTRIBUTOR,
        },
      },
    ],
  },
  {
    path: "/user/:id",
    name: "user",
    component: () => import("@/views/Users/UserEdit.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/user/:id/authors",
    name: "otherUserAuthors",
    component: () => import("@/views/Users/AuthorsOther.vue"),
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
  },
  {
    path: "/user/activate/:token",
    name: "userActivate",
    component: () => import("@/views/Users/UserActivate.vue"),
  },
  {
    path: "/profile",
    name: "ownProfile",
    component: () => import("@/views/Users/Profile.vue"),
    meta: {
      protected: true,
      role: ROLE_CONTRIBUTOR,
    },
  },
  {
    path: "/stale-urls",
    name: "staleUrls",
    meta: {
      protected: true,
      role: ROLE_LECTOR,
    },
    component: () => import("@/views/StaleUrls.vue"),
  },
  {
    path: "/contributor-score-settings",
    name: "contributorScoreSettings",
    meta: {
      protected: true,
      role: ROLE_MANAGER,
    },
    component: () => import("@/views/Workflows/ContributorScoreSettings.vue"),
  },
  {
    path: "/republications",
    name: "republications",
    meta: {
      protected: true,
      role: ROLE_EDITOR,
    },
    component: () => import("@/views/Republications.vue"),
  },
]

export default createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
})
