<template>
  <el-dialog
    :model-value="dialogVisible"
    center
    width="340px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false">
    <template #header>
      <el-image :src="logoUrl" />
    </template>

    <el-form ref="form" :rules="formRules" :model="model" :validate-on-rule-change="false" label-position="top">
      <el-alert v-if="loginError" type="error">
        {{ $t("login_error") }}
      </el-alert>
      <el-form-item prop="email">
        <label class="list-item-label">{{ $t("login") }} ({{ $t("email") }})</label>
        <el-input v-model="model.email" size="large"></el-input>
      </el-form-item>
      <el-form-item prop="pass">
        <label class="list-item-label">{{ $t("password") }}</label>
        <el-input v-model="model.pass" size="large" show-password @keypress.enter="onSubmit"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="onSubmit" size="large" :style="{ width: '100%' }" type="primary">{{
          $t("login")
        }}</el-button>
      </el-form-item>
    </el-form>

    <router-link :to="{ name: 'requestPasswordRecovery' }" :style="{ textAlign: 'right', display: 'block' }"
      ><el-text>{{ $t("forgot_password") }}</el-text></router-link
    >
  </el-dialog>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia"
import useAuthStore from "@/store/auth"
import useRouteStore from "@/store/route"
import { defineComponent } from "vue"
import type ElForm from "element-plus/es/components/form"
import logoUrl from "@/assets/conributor-network-logo.svg"

export default defineComponent({
  name: "Auth",
  i18nOptions: {
    namespaces: ["login_view"],
  },

  data() {
    return {
      loginError: false,
      model: {
        email: "",
        pass: "",
      },
    }
  },
  computed: {
    dialogVisible() {
      const isProtected = !!this.routeMeta?.protected
      const tokenMissing = this.getToken === ""
      const isLoginView = this.routeName === "login"
      const realView = !!this.routeName

      return tokenMissing && (isLoginView || !realView || isProtected)
    },
    ...mapState(useRouteStore, {
      routeMeta: "meta",
      routeName: "name",
    }),
    ...mapState(useAuthStore, {
      getToken: "getToken",
      getGoTo: "getGoTo",
    }),
    formRules() {
      return {
        email: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("errors.field_required"),
          },
          {
            type: "email",
            trigger: "blur",
            message: this.$t("errors.email_invalid"),
          },
        ],
        pass: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("errors.field_required"),
          },
        ],
      }
    },
    logoUrl() {
      return logoUrl
    },
  },
  methods: {
    ...mapActions(useAuthStore, {
      login: "login",
    }),
    async onSubmit() {
      ;(this.$refs["form"] as typeof ElForm).validate(async (valid: boolean) => {
        if (valid) {
          const success = await this.login({
            email: this.model.email,
            pass: this.model.pass,
          })

          this.loginError = !success

          if (this.getGoTo) {
            try {
              await this.$router.replace(this.getGoTo)
            } catch (e) {
              // Empty
            }
          }
        } else {
          return false
        }
      })
    },
  },
})
</script>
