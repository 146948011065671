// // import locale from "element-plus/lib/locale/lang/en";
import { en, de } from "@/locale"

import i18next from "i18next"
// import moment from "moment-timezone"

// import useLangStore from "@/store/lang";
//
//
// const langsUI = {
//   de: deUI,
//   en: enUI,
// };
//
// export const t = (str: string) => i18next.t(str);
export const nst = (ns: string, str: string) => i18next.getFixedT(null, ns)(str)
//
// export const module = () => {
//   return VueI18Next;
// };
//

const date_formats: Record<string, Intl.DateTimeFormatOptions> = {
  "DD.MM.YYYY HH:mm": { dateStyle: "medium", timeStyle: "short" },
  "DD.MM.YYYY": { dateStyle: "short" },
  "D.M.YYYY, H:mm": { dateStyle: "medium", timeStyle: "short" },
}
const default_date_format = { dateStyle: "medium", timeStyle: "short" }

export const init = async () => {
  // locale.use(langsUI[store.getters["lang/getLang"]]);

  const inited = await i18next.init({
    lng: "de",
    fallbackLng: "en",
    resources: {
      de,
      en,
    },
  })

  i18next.services.formatter?.addCached("dtime", (lng, options) => {
    const { format } = options
    const date_format_options = date_formats[format] ?? default_date_format
    const formatter = Intl.DateTimeFormat(lng, date_format_options)

    return (value): string => {
      return value instanceof Date ? formatter.format(value) : "" + value
    }
  })
  return inited
}
