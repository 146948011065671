import useAuthStore from "@/store/auth"

export const ROLE_CONTRIBUTOR = "cms_contributor"
export const ROLE_LECTOR = "cms_lector"
export const ROLE_EDITOR = "cms_editor"
export const ROLE_SEO = "cms_seo"
export const ROLE_MANAGER = "cms_manager"
export const ROLE_ADMIN = "cms_admin"

export const STATUS_PENDING = "pending"
export const STATUS_CONFIRMED = "confirmed"
export const STATUS_BLOCKED = "blocked"

export enum Role {
  CONTRIBUTOR = "cms_contributor",
  LECTOR = "cms_lector",
  EDITOR = "cms_editor",
  SEO = "cms_seo",
  MANAGER = "cms_manager",
  ADMIN = "cms_admin",
}

export enum Status {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  BLOCKED = "blocked",
}
export const ROLES = [ROLE_CONTRIBUTOR, ROLE_LECTOR, ROLE_EDITOR, ROLE_SEO, ROLE_MANAGER, ROLE_ADMIN]

export const STATUSES = [STATUS_PENDING, STATUS_CONFIRMED, STATUS_BLOCKED]

const ROLES_WEIGHTS: Record<string, number> = {
  // eslint-disable-line no-unused-vars
  [""]: 0,
  [ROLE_CONTRIBUTOR]: 1,
  [ROLE_LECTOR]: 2,
  [ROLE_EDITOR]: 3,
  [ROLE_SEO]: 4,
  [ROLE_MANAGER]: 5,
  [ROLE_ADMIN]: 6,
}

export function hasRole(role: string): boolean {
  // @todo Naive implementation, maybe we can do it better?
  const currentUserRole = useAuthStore().getProfile.role ?? ""
  return ROLES_WEIGHTS[role] <= ROLES_WEIGHTS[currentUserRole]
}
